import React, { useContext, useEffect, useCallback, useRef } from "react";
import { ChildrenProps } from "utils/interfaces";
import { EQueryKey, TTokenFormat, TUSDPriceToken } from "types/token";
import { _contract, _near, _walletConnection } from "utils/connect/contract";
import { getUsdtOfToken } from "utils/connect/oracle";
import { useQueryClient } from "react-query";
import {
  landingViewWithoutGettingPrice,
  SUPPORTED_NFT,
  SUPPORTED_TOKENS,
  tokenFormat,
} from "utils/constant";
import { TPoolTokenList } from "utils/types";
import { AppContext } from "./AppContext";
import { useLocation } from "react-router-dom";

const { GET_FORMAT_TOKEN, GET_TOKEN_PRICE_WITH_NAME } = EQueryKey;

const AppInit = ({ children }: ChildrenProps) => {
  const {
    setAuthParams,
    account,
    contract,
    setUserProfile,
    setContract,
    setWallet,
    setPoolTokenList,
    setPoolNftList,
  } = useContext(AppContext);

  const intervalRef = useRef<any>();
  const { pathname } = useLocation();

  const queryClient = useQueryClient();
  const _setQueryData = useCallback(
    (queryKey: string, data: any) => queryClient.setQueryData(queryKey, data),
    [queryClient]
  );

  const initConnect = useCallback(async () => {
    try {
      const initNear = await _near();
      const initWallet = _walletConnection(initNear);
      const initContract: any = _contract(initWallet);
      const accountId =
        initWallet._authData.accountId && initWallet.getAccountId();

      setContract(initContract);
      setWallet(initWallet);

      if (accountId) {
        setAuthParams((prev: any) => ({
          ...prev,
          isLoggedIn: true,
          account: {
            ...prev.account,
            accountId,
          },
        }));
      } else {
        setAuthParams((prev: any) => ({
          ...prev,
          isLoggedIn: false,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [setAuthParams, setContract, setWallet]);

  const _initPool = useCallback(async () => {
    if (!contract || !contract.get_assets_paged_detailed) return;
    await contract
      ?.get_assets_paged_detailed({ from_index: 0, limit: 10 })
      .then((res: any) => {
        const format = res.map((item: TPoolTokenList) => {
          return {
            tokenId: item.token_id,
            ...item,
          };
        });
        setPoolTokenList(
          format.filter((item: TPoolTokenList) =>
            SUPPORTED_TOKENS.find((t) => t === item.token_id)
          )
        );

        setPoolNftList(
          format.filter((item: TPoolTokenList) =>
            SUPPORTED_NFT.find((t) => t === item.token_id)
          )
        );
      })
      .catch((err: any) => console.log(err));
  }, [contract, setPoolNftList, setPoolTokenList]);

  const _initApy = useCallback(async () => {
    if (!contract || !contract.get_assets_paged_detailed) return;
    await contract
      ?.get_assets_apr({ from_index: 0, limit: 10 })
      .then((res: any) => {
        _setQueryData("GET_TOKEN_APY", res);
      })
      .catch((err: any) => console.log(err));
  }, [_setQueryData, contract]);

  const _initAssetFarm = useCallback(async () => {
    if (!contract || !contract.get_asset_farms_paged) return;
    await contract
      ?.get_asset_farms_paged({ from_index: 0, limit: 10 })
      .then((res: any) => {
        // console.log("get_asset_farms_paged", res);
      })
      .catch((err: any) => console.log(err));
  }, [contract]);

  const initPrice = useCallback(async () => {
    const addPriceToTokenFormat = tokenFormat as TTokenFormat;
    const usdTokenPrice: TUSDPriceToken = await getUsdtOfToken();
    if (!usdTokenPrice) return;
    const newTokenFormat: TTokenFormat = Object.entries(usdTokenPrice)?.reduce(
      (_, curr) => {
        const tokenName = curr[0];
        for (const key in addPriceToTokenFormat) {
          const { nameUsd } = addPriceToTokenFormat[key];
          if (tokenName === nameUsd)
            addPriceToTokenFormat[key].usd = usdTokenPrice[tokenName].usd;
        }
        return addPriceToTokenFormat;
      },
      {}
    );

    _setQueryData(GET_TOKEN_PRICE_WITH_NAME, usdTokenPrice);
    _setQueryData(GET_FORMAT_TOKEN, newTokenFormat);
    _initApy();
  }, [_setQueryData, _initApy]);

  const initGetUSDPrice = useCallback(async () => {
    intervalRef.current = setInterval(initPrice, 600);
  }, [initPrice]);

  const _getUserBalance = useCallback(async () => {
    try {
      if (!contract || !account?.accountId) return;
      const user = await contract.get_account({
        account_id: account.accountId,
      });

      if (!user) return;

      const {
        account_id,
        borrowed,
        has_non_farmed_assets,
        nft_supplied,
        supplied,
      } = user || {};

      const farmSupplyAssets = user.farms
        .filter((item) => item.farm_id.Supplied)
        .map((item) => ({ ...item, farm_id: item.farm_id.Supplied }));

      const farmBorrowAssets = user.farms
        .filter((item) => item.farm_id.Borrowed)
        .map((item) => ({ ...item, farm_id: item.farm_id.Borrowed }));

      setUserProfile((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          userBalance: {
            account_id,
            borrowed,
            has_non_farmed_assets,
            nft_supplied,
            supplied,
          },
          userAssetFarms: {
            ...prev.profile.userAssetFarms,
            supplied: farmSupplyAssets,
            borrowed: farmBorrowAssets,
          },
        },
      }));
    } catch (error) {
      console.log(error);
    }
  }, [account, contract, setUserProfile]);

  useEffect(() => {
    initConnect();
  }, [initConnect]);

  useEffect(() => {
    _initAssetFarm();
  }, [_initAssetFarm]);

  useEffect(() => {
    _getUserBalance();
  }, [_getUserBalance]);

  useEffect(() => {
    if (contract?.account?.accountId) {
      setAuthParams((prev: any) => ({
        ...prev,
        isLoggedIn: true,
        account: {
          ...prev.account,
          accountName: contract?.account.accountId,
        },
      }));
      return;
    }
    setAuthParams((prev: any) => ({
      ...prev,
      isLoggedIn: false,
      account: {
        ...prev.account,
        accountName: "",
      },
    }));
  }, [contract?.account?.accountId, setAuthParams]);

  useEffect(() => {
    if (!landingViewWithoutGettingPrice.includes(pathname)) {
      initPrice();
      initGetUSDPrice();
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [initGetUSDPrice, initPrice, pathname]);

  useEffect(() => {
    _initPool();
  }, [_initPool]);

  useEffect(() => {
    _initApy();
  }, [_initApy]);

  useEffect(() => {
    initPrice();
  }, [initPrice]);

  return <>{children}</>;
};

export default AppInit;
