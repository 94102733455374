import React from "react";
import benefit1 from "images/benefit1.png";
import benefit2 from "images/benefit2.png";
import benefit3 from "images/benefit3.png";
import benefit4 from "images/benefit4.png";
import benefit5 from "images/benefit5.png";
import benefit6 from "images/benefit6.png";
import { Container } from "components/common/Container";

const LangbiangBenefit = () => {
  return (
    <div className="benefits-box">
      <Container data-aos="fade-right">
        <div className="benefits">
          <span className="title">
            <span className="text">Benefits of</span>{" "}
            <span className="lang-biang">Lang Biang</span>{" "}
            <span className="text"> NFT Owners</span>
          </span>
          <div className="lst-benefits-img">
            {nfts.map((item: any, idx: number) => {
              return (
                <div key={idx} className="benefit" data-aos="fade-left">
                  <span>
                    <img src={item.url} alt={item.alt} />
                    <p className="idx">{item.idx}</p>
                    <p className="desc">{item.desc}</p>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LangbiangBenefit;

const nfts = [
  {
    idx: "01",
    url: benefit1,
    alt: "benefit1",
    desc: "Access to the Lang Biang Club",
  },
  {
    idx: "02",
    url: benefit2,
    alt: "benefit2",
    desc: "Become early users and strategic partners of Nearlend DAO",
  },
  {
    idx: "03",
    url: benefit3,
    alt: "benefit3",
    desc: "Join in the pre-mining program for up to 15.000.000 $NEL",
  },
  {
    idx: "04",
    url: benefit4,
    alt: "benefit4",
    desc: "Deposit Lang Biang as Collateral in Nearlend Protocol",
  },
  {
    idx: "05",
    url: benefit5,
    alt: "benefit5",
    desc: "Alpha, airdrops & premium whitelist",
  },
  {
    idx: "06",
    url: benefit6,
    alt: "benefit6",
    desc: "Many other yet-to-be-announced benefits",
  },
];
