import { IContractMain } from "utils/interfaces";

const ContractMain: IContractMain = {
  contract: null,
  setContract: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

export default ContractMain;
