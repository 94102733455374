import nfts_1 from "images/nfts_1.png";
import nfts_2 from "images/nfts_2.png";
import nfts_3 from "images/nfts_3.png";
import nfts_4 from "images/nfts_4.png";
import nfts_5 from "images/nfts_5.png";
import nfts_6 from "images/nfts_6.png";
import nfts_7 from "images/nfts_7.png";
import nfts_8 from "images/nfts_8.png";
import nfts_9 from "images/nfts_9.png";
import nfts_10 from "images/nfts_10.png";
import nfts_11 from "images/nfts_11.png";
import nfts_12 from "images/nfts_12.png";
import nfts_13 from "images/nfts_13.png";
import nfts_14 from "images/nfts_14.png";
import nfts_15 from "images/nfts_15.png";
import nfts_16 from "images/nfts_16.png";
import nfts_17 from "images/nfts_17.png";
import nfts_18 from "images/nfts_18.png";
import nfts_19 from "images/nfts_19.png";
import nfts_20 from "images/nfts_20.png";
import React from "react";
import { Container } from "components/common/Container";

const paras = [
  {
    url: nfts_1,
    alt: "nfts_1",
  },
  {
    url: nfts_2,
    alt: "nfts_2",
  },
  {
    url: nfts_3,
    alt: "nfts_3",
  },
  {
    url: nfts_4,
    alt: "nfts_4",
  },
  {
    url: nfts_5,
    alt: "nfts_5",
  },
  {
    url: nfts_6,
    alt: "nfts_6",
  },
  {
    url: nfts_7,
    alt: "nfts_7",
  },
  {
    url: nfts_8,
    alt: "nfts_8",
  },
  {
    url: nfts_9,
    alt: "nfts_9",
  },
  {
    url: nfts_10,
    alt: "nfts_10",
  },
  {
    url: nfts_11,
    alt: "nfts_11",
  },
  {
    url: nfts_12,
    alt: "nfts_12",
  },
  {
    url: nfts_13,
    alt: "nfts_13",
  },
  {
    url: nfts_14,
    alt: "nfts_14",
  },
  {
    url: nfts_15,
    alt: "nfts_15",
  },
  {
    url: nfts_16,
    alt: "nfts_16",
  },
  {
    url: nfts_17,
    alt: "nfts_17",
  },
  {
    url: nfts_18,
    alt: "nfts_18",
  },
  {
    url: nfts_19,
    alt: "nfts_19",
  },
  {
    url: nfts_20,
    alt: "nfts_20",
  },
];

const NftsCollection = () => {
  return (
    <div className="nfts-box">
      <Container className="nft-box-container">
        <div className="nfts">
          <div className="desc-box" data-aos="fade-up">
            <h3>Lang Biang</h3>
            <p className="desc">333 NFTs</p>
            <p className="inspired">inspired by traditional Vietnamese art.</p>
            <a
              href="https://marketplace-v2-testnet.paras.id/collection/nft.nearlend-official.testnet"
              target="_blank"
              rel="noreferrer"
              className="paras"
            >
              Shop Now
            </a>
          </div>
          <div className="nfts-box-list">
            {paras.map((item: any, idx: number) => {
              return (
                <div key={idx} className="box" data-aos="fade-down">
                  <img src={item.url} alt={item.alt} />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NftsCollection;
