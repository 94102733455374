import BannerUI from "components/homepage/Banner";
import InvesterUI from "components/homepage/Invester";
import SolutionUI from "components/homepage/Solution";
import TokenomicUI from "components/homepage/Tokenomic";
import { MainWrapper, TopWrapper } from "./styles";

import AOS from "aos";
import { useEffect } from "react";
import styled from "styled-components";
import { useWindowDimensions } from "hooks/useWindowSize";
import { mobileBreakPoint } from "utils/constant";

const OpeningPage = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    AOS.init({
      duration: 1500,
      disable: width < mobileBreakPoint,
    });
    return () => AOS.refresh();
  }, [width]);

  return (
    <MainWrapper className="mobile-homepage-only">
      <TopWrapper>
        <BannerUI />
        <SolutionUI />
      </TopWrapper>
      <Wrapper>
        <TokenomicUI />
        <InvesterUI />
      </Wrapper>
    </MainWrapper>
  );
};

export default OpeningPage;

const Wrapper = styled.div`
  position: relative;
`;
