import { IWallet } from "utils/interfaces";

const Wallet: IWallet = {
  wallet: null,
  setWallet: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

export default Wallet;
