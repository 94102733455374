import useCurrentNft from "hooks/useCurrentNft";
import tokenNel from "images/token_nel.png";
import { formatCashToView } from "utils/common";
import useImageExist from "hooks/useImageExist";
function AssetNftItem({ item }: any) {
  const { tokenUsdPriceRatio } = useCurrentNft(item);
  const { image } = useImageExist({ url: item.metadata.media });

  const nearWalletUrl = `https://wallet.testnet.near.org/nft-detail/${item.contractName}`;

  return (
    <tr>
      <td className="card">
        <div className="wrap-img">
          <div className="img">
            <div className="img-nft">
              <img
                src={image}
                alt={item.metadata ? item.metadata.description : ""}
                width="198"
                height="198"
              />
            </div>
            <div className="nft-info">
              <span className="nft-name">
                <a
                  href={`${nearWalletUrl}/${item.token_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.metadata.title}
                </a>
              </span>
              <span className="nftId">
                ID#:<span>{item.token_id}</span>
              </span>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="floor-price">
          <div className="left price-text">
            <p>
              N/A
              <img className="logo-floor" src={tokenNel} alt="logo-floor" />
            </p>
          </div>
        </div>
        <div className="usd-price">
          <div className="left">
            <p>${formatCashToView(tokenUsdPriceRatio, 8)}</p>
          </div>
        </div>
      </td>
      {/* <td>
        <div className="floor-price">
          <div className="left price-text">
            <p>100.21
              <img
                className="logo-floor"
                src={tokenNel}
                alt="logo-floor"
              /></p>
          </div>
        </div>
        <div className="usd-price">
          <div className="left">
            <p>$21</p>
          </div>
        </div>
      </td> */}
    </tr>
  );
}

export default AssetNftItem;
