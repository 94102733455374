import React, { useContext } from "react";
import { Link } from "react-router-dom";
// import type { PaginationProps } from "antd";
import { AppContext } from "Contexts/AppContext";

// import NearPagination from "components/common/Pagination";
import BalanceNFTDepositItem from "components/myBalance/balance-nft/BalanceNFTDepositItem";

export default function BalanceNFTDeposit({ supplied }: any) {
  const { profile } = useContext(AppContext);

  // const [paginSetting, setPaginSetting] = useState({
  //   total: 50,
  //   current: 1,
  // });

  // const [paginAssetSetting, setPaginAssetSetting] = useState({
  //   total: 12,
  //   current: 1,
  // });

  // const handlePaginOnchange: PaginationProps["onChange"] = (page) => {
  //   setPaginSetting((prev) => ({ ...prev, current: page }));
  // };

  // const handlePaginAssetOnchange: PaginationProps["onChange"] = (page) => {
  //   setPaginAssetSetting((prev) => ({ ...prev, current: page }));
  // };

  return (
    <div className="deposit-tab">
      <h3 className="big-title">
        <span style={{ color: "#49E454" }}>Deposited</span> assets
      </h3>
      <div className="token-list">
        <div className="side">
          <div className="detail">
            <div className="label label__title">
              <p className="title">Asset</p>
              <p className="title">Rewards</p>
              <p className="title">AVG Floor (7D)</p>
              <p className="title">Actions</p>
            </div>
            {profile?.userBalance?.nft_supplied.length ? (
              profile?.userBalance?.nft_supplied.map(
                (item: any, idx: number) => {
                  return item ? (
                    <BalanceNFTDepositItem key={idx} itemToken={item} />
                  ) : null;
                }
              )
            ) : (
              <div className="empty-account-line">
                <p>
                  Not Available. Go to <Link to="/vaults">Deposit</Link> now !
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <NearPagination
      current={paginSetting?.current}
      total={paginSetting?.total}
      paginOnchange={handlePaginOnchange}
    /> */}
    </div>
  );
}
