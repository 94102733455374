import { useCallback, useContext, useEffect, useRef, useState } from "react";
import PopupRepay from "components/popup/actions/Repay";
import useCurrentToken from "hooks/useCurrentToken";
import useMinimumDollar from "components/myBalance/hooks/useMinimumDollar";
import BalanceTokenItem from "components/myBalance/components/BalanceTokenItem";
import { AppContext } from "Contexts/AppContext";
import Big from "big.js";
import {
  ENV_ID_TOKEN_NEL,
  LAST_DECIMAL_REPLACE,
  QUERY_KEY,
} from "utils/constant";
import { useQueryClient } from "react-query";

const BalanceBorrowItem = ({ currentToken }: any) => {
  const queryClient = useQueryClient();
  const refGetPriceRealtime = useRef<any>();
  const { profile } = useContext(AppContext);
  const {
    tokenIcon,
    tokenSymbol,
    tokenContractDecimals,
    tokenExtraDecimals,
    borrow_apr,
    tokenId,
  } = useCurrentToken(currentToken);
  const [isShowWithdraw, setIsShowWithdraw] = useState(false);
  const [currentReward, setCurrentReward] = useState(0);
  const [usd, setUsd] = useState(0);

  const isAllowToShow = useMinimumDollar(currentToken);

  const _getPriceRealtime = useCallback(() => {
    const tokenFormat = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as any;
    setUsd(tokenFormat?.[tokenId]?.usd || 0);
  }, [queryClient, tokenId]);

  const _initGetPriceRealtime = useCallback(() => {
    refGetPriceRealtime.current = setInterval(_getPriceRealtime, 2500);
  }, [_getPriceRealtime]);

  const foundBorrowTokenId = profile?.userBalance?.borrowed?.find(
    (item: any) => item.token_id === tokenId
  );
  const borrowedBalance = foundBorrowTokenId?.balance;
  const _handleToggleWithdraw = () => {
    setIsShowWithdraw(!isShowWithdraw);
  };

  const _initReward = useCallback(() => {
    const reward_supply = profile.userAssetFarms.borrowed.find(
      (item) => item.farm_id === tokenId
    );

    const nel_reward = reward_supply?.rewards.find(
      (r) => r.reward_token_id === ENV_ID_TOKEN_NEL
    );

    if (!nel_reward) return;

    setCurrentReward(
      Number(
        Big(nel_reward?.asset_farm_reward.reward_per_day)
          .div(Big(nel_reward?.asset_farm_reward.boosted_shares))
          .mul(Big(nel_reward?.boosted_shares))
          .div(Big(10).pow(24))
          .toNumber()
          .toFixed(9)
          .slice(0, LAST_DECIMAL_REPLACE)
      )
    );
  }, [profile.userAssetFarms.borrowed, tokenId]);

  useEffect(() => {
    _initReward();
  }, [_initReward]);

  useEffect(() => {
    _getPriceRealtime();
    _initGetPriceRealtime();
    return () => {
      clearInterval(refGetPriceRealtime.current);
    };
  }, [_initGetPriceRealtime, _getPriceRealtime]);

  return (
    <div className="wrap-info active">
      {isAllowToShow && (
        <BalanceTokenItem
          tokenIcon={currentToken?.icon ?? tokenIcon}
          tokenUsd={usd}
          tokenSymbol={tokenSymbol}
          tokenDecimals={tokenContractDecimals + tokenExtraDecimals}
          apy={borrow_apr}
          balance={borrowedBalance}
          actions={[{ func: _handleToggleWithdraw, name: "Repay" }]}
          reward={currentReward}
        />
      )}
      {isShowWithdraw && (
        <PopupRepay
          togglePopup={_handleToggleWithdraw}
          currentToken={foundBorrowTokenId}
        />
      )}
    </div>
  );
};

export default BalanceBorrowItem;
