import "components/liquidity/style.scss";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppContext } from "Contexts/AppContext";

export const RewardOtherToken = () => {
  let {
    profile: {
      userAssetFarms: { borrowed, supplied },
    },
  } = useContext(AppContext);

  const [data, setData] = useState([]);

  const _initMergeRewards = useCallback(() => {
    const mergeRewardsByFarmId: {
      id: string;
      rewards: any[];
    }[] = supplied?.reduce((acc, curr) => {
      if (borrowed.length !== 0) {
        borrowed.forEach((borrow) => {
          if (borrow.farm_id === curr.farm_id) {
            let newCurr = {
              id: borrow.farm_id || curr.farm_id,
              rewards: [...borrow.rewards, ...curr.rewards],
            };
            acc.push(newCurr);
          }
        });
      } else {
        let newCurr = {
          id: curr.farm_id,
          rewards: [...curr.rewards],
        };
        acc.push(newCurr);
      }
      return acc;
    }, []);

    setData(mergeRewardsByFarmId);
  }, [borrowed, supplied]);

  useEffect(() => {
    _initMergeRewards();
  }, [_initMergeRewards]);

  return (
    <Fragment>
      <h3 className="liquidity-title">Others</h3>
      <p className="liquidity-desc">
        All your claimable rewards are summarized here
      </p>
      <div className="token-board">
        <div className="liquidity-token-cap">
          <h3>Assets</h3>
          <h3>Rewards</h3>
          {/* <h3>Actions</h3> */}
        </div>
        <div className="liquidity-token-list">
          {/* {data.map((item) => (
            <LiquidityTokenItem key={item.id} data={item} />
          ))} */}
          <div className="liquidity-token">
            <p>Coming soon !</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
