import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Image from "components/common/Images";
import near_logo from "images/logo.png";
import { AppContext } from "Contexts/AppContext";
import { useQueryClient } from "react-query";
import { ENV_ID_TOKEN_wNEAR, QUERY_KEY } from "utils/constant";
import { TTokenFormat } from "utils/types";
import { formatCashToView, totalContractNft } from "utils/common";

const TabsHead = ({ contractNftId }) => {
  const queryClient = useQueryClient();
  const getNewFormatToken = queryClient.getQueryData(
    QUERY_KEY.GET_FORMAT_TOKEN
  ) as unknown as TTokenFormat;

  const { poolNftList } = useContext(AppContext);

  const [data, setData] = useState([]);

  const _getCurrentNftsVault = useCallback(async () => {
    const found =
      poolNftList &&
      poolNftList.find((item) => item.token_id === contractNftId);

    if (!found) return setData(emptyData);
    const ratio = found.config.volatility_ratio / 100;
    const near_amount =
      getNewFormatToken?.[contractNftId].usd /
      getNewFormatToken?.[ENV_ID_TOKEN_wNEAR].usd;
    const nft_floor_price = getNewFormatToken?.[contractNftId].usd;

    const totalPriceNft = totalContractNft(
      poolNftList,
      contractNftId,
      getNewFormatToken,
      false
    );

    // const total_near_in_vault =
    //   Number(totalPriceNft.toFixed(2)) /
    //   getNewFormatToken?.[ENV_ID_TOKEN_wNEAR].usd;

    setData([
      {
        label: "NFTs in Vaults",
        value: found.nft_supplied.length,
      },
      {
        label: "Rewards",
        value: near_logo,
        img: true,
      },
      {
        label: "AVG Floor (7D)",
        value: `${formatCashToView(near_amount, 8)} NEAR`,
        child: `$${formatCashToView(nft_floor_price, 8)}`,
      },
      {
        label: "Collateral Value",
        value: `Max ${ratio}%`,
      },
      {
        label: "Value Locked in Vault",
        value: `$${formatCashToView(totalPriceNft, 8)}`,
        // value: `${formatCashToView(total_near_in_vault, 8)} NEAR`,
        // child: `$${formatCashToView(totalPriceNft, 8)}`,:
      },
    ]);
  }, [contractNftId, getNewFormatToken, poolNftList]);

  useEffect(() => {
    _getCurrentNftsVault();
  }, [_getCurrentNftsVault]);

  return (
    <WrapHead>
      <FlexUI>
        {data.map((item, idx) => {
          return (
            <div key={idx} className="head-item">
              <div className="label">{item?.label}</div>
              <div className="value">
                {item?.img ? (
                  <span
                    className="main-value"
                    style={{
                      width: "35px",
                      height: "35px",
                      display: "inline-flex",
                      background: "white",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 5,
                      borderRadius: "100%",
                    }}
                  >
                    <Image src={item?.value} />
                  </span>
                ) : (
                  <span className="main-value">{item?.value}</span>
                )}

                {item?.child && (
                  <span className="sub-value">{`(${item?.child})`}</span>
                )}
              </div>
            </div>
          );
        })}
      </FlexUI>
    </WrapHead>
  );
};

export default React.memo(TabsHead);

const FlexUI = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const WrapHead = styled.div`
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
`;

const emptyData = [
  {
    label: "NFTs in Vaults",
    value: "N/A",
  },
  {
    label: "Rewards",
    value: near_logo,
    img: true,
  },
  {
    label: "AVG Floor (7D)",
    value: "N/A",
    child: "",
  },
  {
    label: "Collateral Value",
    value: "N/A",
  },
  {
    label: "Value Locked in Vault",
    value: "N/A",
    child: "",
  },
];
