import React, { useContext } from "react";
import { Tabs } from "antd";
import TotalBalance from "components/myBalance/components/TotalBalance";
import BalanceDeposit from "components/myBalance/balance-deposit/BalanceDeposit";
import NFTDeposit from "components/myBalance/balance-nft/BalanceNFTDeposit";
import BalanceBorrow from "components/myBalance/balance-borrow/BalanceBorrow";
import SocialFooter from "components/common/SocialFooter";
import { AppContext } from "Contexts/AppContext";
import LoginRequest from "components/common/LoginRequest";
import { Container } from "components/common/Container";

const { TabPane } = Tabs;

const onChange = (key: string) => {
  console.log(key);
};

export default function MyBalance() {
  const { isLoggedIn } = useContext(AppContext);

  return (
    <main className="bg-main flex-vertical">
      <div>
        <div className="container portfolio homepage">
          {isLoggedIn ? (
            <>
              <TotalBalance />
              <Container>
                <Tabs id="tab-view" defaultActiveKey="1" onChange={onChange}>
                  <TabPane tab="Crypto" key="1">
                    <div className="balance-token">
                      <BalanceDeposit />
                    </div>
                  </TabPane>
                  <TabPane tab="NFT" key="2">
                    <div className="balance-token">
                      <NFTDeposit />
                    </div>
                  </TabPane>
                </Tabs>
              </Container>
              <div className="balance-token">
                <BalanceBorrow />
              </div>
            </>
          ) : (
            <LoginRequest />
          )}
        </div>
      </div>
      <SocialFooter />
    </main>
  );
}
