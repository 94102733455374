import { Image as AntImage } from "antd";

const Image = (props: any) => {
  const { style, ...prop } = props;
  return (
    <div style={{ position: "relative", ...prop }}>
      <AntImage preview={false} style={style} {...props} />
      {props.children}
    </div>
  );
};

export default Image;
