/* eslint-disable jsx-a11y/iframe-has-title */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";

import "components/marketApp/style.scss";
import "styles/App.scss";

import { Container } from "components/common/Container";
import SocialFooter from "components/common/SocialFooter";
import TokenList from "components/marketApp/marketToken/TokenList";
import {
  BorrowPopup,
  BorrowStablePopup,
  DepositPopup,
  RegisterFirstTime,
  RequireLogin,
} from "components/popup";
import { AppContext } from "Contexts/AppContext";
import { TTokenFormat } from "types/token";
import {
  errorPipeline,
  formatCashToView,
  totalMarket,
  totalMarketNft,
} from "utils/common";
import {
  claimFreeToken,
  handleRegisterToken,
  isUserRegisterToken,
} from "utils/connect/contract";
import {
  ACTION,
  ENV_ID_MAIN_CONTRACT,
  ENV_ID_TOKEN_wNEAR,
  QUERY_KEY,
  tokenFormat,
} from "utils/constant";

import useLocalStore from "hooks/useLocalStore";
import "responsive.css";

function MarketApp() {
  const initInterval = useRef<any>();
  const queryClient = useQueryClient();
  const { contract, wallet, poolTokenList, poolNftList, isLoggedIn } =
    useContext(AppContext);

  const { getLocalStore, setLocalStore } = useLocalStore();

  const [isShowAuctionButton, setIsShowAuctionButton] = useState(true);
  const [isShowPopupDeposit, setIsShowPopupDeposit] = useState(false);
  const [isShowPopupBorrow, setIsShowPopupBorrow] = useState(false);
  const [isShowPopupBorrowStable, setIsShowPopupBorrowStable] = useState(false);
  const [isShowPopupRegister, setIsShowPopupRegister] = useState(false);
  const [isShowPopupRequireLogin, setIsShowPopupRequireLogin] = useState(false);
  const [isShowPopupRequire, setIsShowPopupPopupRequire] = useState(false);
  const [tokenChose, setTokenChose]: any = useState(null);
  const [popupRequire, setPopupRequire] = useState({
    textTitle: "",
    textConfirm: "",
    textCancel: "",
    handleConfirm: () => {},
  });

  const [usdTotalDeposited, setUsdTotalDeposited] = useState(0);
  const [usdTotalBorrowed, setUsdTotalBorrowed] = useState(0);

  const _handleTokenRegister = useCallback(
    async (item: any) => {
      const tokenId = item?.tokenId || item?.token_id;
      if (!tokenId) return;
      await handleRegisterToken(contract, wallet, tokenId);
    },
    [contract, wallet]
  );

  const _checkIsTokenRegister = useCallback(
    async (item) => {
      return await isUserRegisterToken(contract, wallet, item.token_id);
    },
    [contract, wallet]
  );

  const _openActionPopup = useCallback((type: string) => {
    switch (type) {
      case "deposit":
        setIsShowPopupDeposit(true);
        break;
      case "borrow":
        setIsShowPopupBorrow(true);
        break;
      case "borrowStable":
        setIsShowPopupBorrowStable(true);
        break;
      default:
        return null;
    }
  }, []);

  const _openTokenRegisterPopup = useCallback(
    (item) => {
      setPopupRequire({
        textTitle: "Need to register this token !",
        textConfirm: "Register",
        textCancel: "Cancel",
        handleConfirm: () => _handleTokenRegister(item),
      });
      setIsShowPopupPopupRequire(true);
    },
    [_handleTokenRegister]
  );

  const _handleClaim = useCallback(
    async (item: any) => {
      const tokenId = item?.tokenId || item?.token_id;
      const decimals = tokenFormat[tokenId].contract_decimals;
      if (!tokenId && !decimals) return;
      const amountClaim = 200 * 10 ** decimals;
      await claimFreeToken(contract, wallet, amountClaim, tokenId);
    },
    [contract, wallet]
  );

  const _openClaimSwapPopup = useCallback(
    (item) => {
      const text =
        item.token_id === ENV_ID_TOKEN_wNEAR
          ? `You want to Swap from NEAR to wNEAR ?\n(only testnet)`
          : `You want to claim this token ?\n(only testnet)`;
      const text_confirm =
        item.token_id === ENV_ID_TOKEN_wNEAR ? "Swap" : "Claim";
      setPopupRequire({
        textTitle: text,
        textConfirm: text_confirm,
        textCancel: "Cancel",
        handleConfirm: () => _handleClaim(item),
      });
    },
    [_handleClaim]
  );

  const _pipelineValidateActions = useCallback(
    async (type: string, item: any) => {
      const _isTokenRegister = await _checkIsTokenRegister(item);
      const isUserRegisterWithNearLendPool = await isUserRegisterToken(
        contract,
        wallet,
        ENV_ID_MAIN_CONTRACT
      );

      const isNotLogin = () => {
        if (!isLoggedIn) {
          setIsShowPopupRequireLogin(true);
          return "not logged yet !";
        }
        return "";
      };

      const isLogged = (type) => {
        if (isLoggedIn) {
          _openActionPopup(type);
          return "";
        }
      };

      const errorTokenRegister = () => {
        if (_isTokenRegister === null) {
          _openTokenRegisterPopup(item);
          return "not register token yet !";
        }
        return "";
      };

      const isUserRegisterNearLendPool = () => {
        if (
          isUserRegisterWithNearLendPool === null ||
          isUserRegisterWithNearLendPool.available === "0"
        ) {
          setIsShowPopupRegister(true);
          return "not register with nearlend-dao's pool yet !";
        }
        return "";
      };

      errorPipeline(
        () => isNotLogin(),
        () => errorTokenRegister(),
        () => isUserRegisterNearLendPool(),
        () => isLogged(type)
      )("");
    },
    [
      _checkIsTokenRegister,
      _openActionPopup,
      _openTokenRegisterPopup,
      contract,
      isLoggedIn,
      wallet,
    ]
  );

  const _pipelineValidateClaimSwapActions = async (item) => {
    const isNotLogin = () => {
      if (!isLoggedIn) {
        setPopupRequire({
          textTitle: "You need to Login to claim !",
          textConfirm: "Log In",
          textCancel: "Cancel",
          handleConfirm: () => {},
        });
        setIsShowPopupRequireLogin(true);
        return "not logged yet !";
      }
      return "";
    };

    // This line will toggle whenever click Confirm or Cancel Popup
    setIsShowPopupPopupRequire((prevState) => !prevState);

    const tokenId = item?.tokenId || item?.token_id;
    if (!tokenId) return;
    const _isTokenRegister = await _checkIsTokenRegister(item);

    const isTokenRegister = () => {
      if (_isTokenRegister === null) {
        _openTokenRegisterPopup(item);
        return "not register token yet !";
      }
      return "";
    };

    const openClaimSwapPopup = () => {
      if (_isTokenRegister) {
        _openClaimSwapPopup(item);
        return "";
      }
      return "not register token yet !";
    };

    errorPipeline(
      () => isNotLogin(),
      () => isTokenRegister(),
      () => openClaimSwapPopup()
    )("");
  };

  const _handleTogglePopupRequire = async (item: any) =>
    await _pipelineValidateClaimSwapActions(item);

  const setUpPopup = (e: any, item: any) => {
    e.preventDefault();
    setTokenChose(item);
  };

  const openPopupDeposit = useCallback(
    (e: any, item: any) => {
      setUpPopup(e, item);
      _pipelineValidateActions(ACTION.DEPOSIT.toLowerCase(), item);
    },
    [_pipelineValidateActions]
  );

  const openPopupBorrow = useCallback(
    (e: any, item: any) => {
      setUpPopup(e, item);
      _pipelineValidateActions(ACTION.BORROW.toLowerCase(), item);
    },
    [_pipelineValidateActions]
  );

  const openPopupBorrowStable = (e: any, item: any) => {
    setUpPopup(e, item);
    _pipelineValidateActions("borrowStable", item);
  };
  const _handleTogglePopupDeposit = () => {
    setIsShowPopupDeposit((prevState) => !prevState);
  };

  const _handleTogglePopupBorrow = () => {
    setIsShowPopupBorrow((prevState) => !prevState);
  };

  const _handleTogglePopupBorrowStable = () => {
    setIsShowPopupBorrowStable((prevState) => !prevState);
  };

  const _handleTogglePopupRegister = () => {
    setIsShowPopupRegister((prevState) => !prevState);
  };

  const _handleTogglePopupRequireLogin = () => {
    setPopupRequire({
      textTitle: `${'You need to Login to "Deposit" or "Borrow" !'}`,
      textConfirm: "Log In",
      textCancel: "Cancel",
      handleConfirm: () => {},
    });
    setIsShowPopupRequireLogin((prev) => !prev);
  };

  const getTotalDeposited = useCallback(() => {
    const tokenFormat = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    if (!poolTokenList || !tokenFormat) {
      setUsdTotalDeposited(0);
      setUsdTotalBorrowed(0);
      return;
    }

    const deposited__To__USDT = totalMarket(
      poolTokenList,
      "supplied",
      tokenFormat
    );

    const nft_deposited_to_USDT = totalMarketNft(
      poolNftList,
      tokenFormat,
      false
    );

    const reserved__To__USDT = totalMarket(
      poolTokenList,
      "reserved",
      tokenFormat
    );

    const totalSupplied__To__USDT =
      nft_deposited_to_USDT + deposited__To__USDT + reserved__To__USDT;

    const borrowed__To__USDT = totalMarket(
      poolTokenList,
      "borrowed",
      tokenFormat
    );

    setUsdTotalDeposited(Number(totalSupplied__To__USDT));
    setUsdTotalBorrowed(Number(borrowed__To__USDT));
  }, [poolNftList, poolTokenList, queryClient]);

  useEffect(() => {
    initInterval.current = setInterval(getTotalDeposited, 400);
    return () => {
      clearInterval(initInterval.current);
    };
  }, [getTotalDeposited]);

  useEffect(() => {
    if (getLocalStore("isClickAuction") === "false")
      setIsShowAuctionButton(false);
  }, [getLocalStore]);

  return (
    <div className="homepage market-app ">
      <Container>
        <div className="wrap-total">
          <div className="sub-wrapp">
            <div className="total deposit">
              <p className="title">Total Supplied</p>
              <p className="value">${formatCashToView(usdTotalDeposited, 9)}</p>
            </div>
          </div>

          <div className="sub-wrapp">
            <div className="total borrow">
              <p className="title">Total Borrowed</p>
              <p className="value">${formatCashToView(usdTotalBorrowed, 9)}</p>
            </div>
          </div>
        </div>

        <div className="wrap-market">
          {/* {isShowAuctionButton && (
            <div className="auction-btn-wrap">
              <button onClick={handleRouteAuction}>
                <img
                  alt="Auction with Nearlend DAO"
                  src={icon_close}
                  width={24}
                  height={24}
                />
              </button>
              <a
                className="link-auction"
                href={AuctionUrl}
                rel="noreferrer"
                target={"_blank"}
              >
                <img
                  alt="Auction with Nearlend DAO"
                  src={auctionnow_img}
                  width={165}
                  height={100}
                />
              </a>
            </div>
          )} */}
          <h5 className="title">Market overview</h5>
          <div className="section-market">
            <div className="wrap-label">
              <p className="w-100">Asset</p>
              <p className="w-50 text-center">Rewards</p>
              <p className="w-100 text-right">Total Supplied</p>
              <p className="w-100 text-right">Total Borrowed</p>
              <p className="w-150 text-center">Supply APY</p>
              <p className="w-150 text-center">Borrow APY</p>
              {/* <p className="w-150">
              Borrow APY <br /> (stable)
            </p> */}
            </div>
            <div className="pools">
              <TokenList
                setTurnOff={setIsShowPopupPopupRequire}
                tokenList={poolTokenList}
                actions={{
                  _openPopupDeposit: openPopupDeposit,
                  _openPopupBorrow: openPopupBorrow,
                  _openPopupBorrowStable: openPopupBorrowStable,
                  _handleTogglePopupRequire: _handleTogglePopupRequire,
                }}
              />
            </div>
          </div>
        </div>
        {isShowPopupDeposit && (
          <DepositPopup
            currentToken={tokenChose}
            togglePopup={_handleTogglePopupDeposit}
          />
        )}
        {isShowPopupBorrow && (
          <BorrowPopup
            currentToken={tokenChose}
            togglePopup={_handleTogglePopupBorrow}
            totalBorrow={usdTotalBorrowed}
          />
        )}
        {isShowPopupBorrowStable && (
          <BorrowStablePopup
            currentToken={tokenChose}
            togglePopup={_handleTogglePopupBorrowStable}
          />
        )}
        {isShowPopupRegister && (
          <RegisterFirstTime setTurnOff={_handleTogglePopupRegister} />
        )}
        {isShowPopupRequireLogin && (
          <RequireLogin
            textTitle={
              popupRequire.textTitle ||
              "You need to Login to deposit or borrow !"
            }
            textConfirm={popupRequire.textConfirm || "Log In"}
            textCancel={popupRequire.textCancel || "Cancel"}
            togglePopup={_handleTogglePopupRequireLogin}
          />
        )}
        {isShowPopupRequire && (
          <RequireLogin
            textTitle={popupRequire.textTitle}
            textConfirm={popupRequire.textConfirm}
            textCancel={popupRequire.textCancel}
            togglePopup={_handleTogglePopupRequire}
            handleConfirm={popupRequire.handleConfirm}
          />
        )}
      </Container>
      <SocialFooter />
    </div>
  );
}

export default MarketApp;
