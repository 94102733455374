import React from "react";
import { Icos, WrappSvg } from "components/footer";
import reportbug_img from "images/reportbug-btn.png";
// import feedback_img from "images/feedback-btn.png";
import { useLocation } from "react-router-dom";

const SocialFooter = () => {
  const location = useLocation();

  return (
    <div className="icons-bottom">
      {location.pathname !== "/" ? (
        <div className="test-net-button">
          {/* <a
            target={"_blank"}
            href="https://forms.gle/3NgqLe4to88xcY4h7"
            rel="noreferrer"
          >
            <img
              alt="Feedback to Nearlend DAO"
              src={feedback_img}
              width={133}
              height={40}
            />
          </a> */}
          <a
            target={"_blank"}
            href="https://forms.gle/4dzwm7bn7LtNBe7g9"
            rel="noreferrer"
          >
            <img
              alt="Report bug to Nearlend DAO"
              src={reportbug_img}
              width={133}
              height={40}
            />
          </a>
        </div>
      ) : null}
      <div className="icons-wrapper">
        {Icos.map((item, idx) => (
          <WrappSvg
            key={idx}
            href={item.path}
            target="_blank"
            rel="noreferrer"
            className="icon-item"
          >
            {item.src}
          </WrappSvg>
        ))}
      </div>
    </div>
  );
};

export default SocialFooter;
