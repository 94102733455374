import { useQueryClient } from "react-query";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { formatBalanceWithDecimal, formatCashToView } from "utils/common";
import { ENV_EXPLORER_URL, QUERY_KEY, tokenFormat } from "utils/constant";
import { TTokenFormat } from "types/token";
import {
  handleGetNearBalance,
  handleGetUserAssetToken,
} from "../../utils/connect/contract";
import { AppContext } from "../../Contexts/AppContext";
import useCurrentToken from "../../hooks/useCurrentToken";

function TokenItem({ item, only_near = false }: any) {
  const intervalRef = useRef<any>();
  const queryClient = useQueryClient();
  const nearWalletUrl = ENV_EXPLORER_URL;
  const { contract, wallet } = useContext(AppContext);
  const tokenId = item;

  let icon = tokenFormat[tokenId.toString()]?.icon;
  icon = icon ? icon : "#";
  const tokenSymbol = !only_near
    ? tokenFormat[tokenId.toString()]?.symbol
    : "NEAR";
  const [tokenUsd, setTokenUsd] = useState(0);
  const [balanceOnWallet, setBalanceOnWallet] = useState(0);

  const { tokenExtraDecimals, tokenContractDecimals } = useCurrentToken(item);

  const _calculate = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    if (!getNewFormatToken) return;
    const usd = getNewFormatToken[tokenId] ? getNewFormatToken[tokenId].usd : 0;

    setTokenUsd(usd);
  }, [queryClient, tokenId]);

  const _initCalculate = useCallback(() => {
    const initCalculate = () => _calculate();
    intervalRef.current = setInterval(initCalculate, 400);
  }, [_calculate]);

  const _handleGetUserAssetTokens = useCallback(async () => {
    try {
      let balance = await handleGetUserAssetToken(contract, wallet, tokenId);
      const tokenDecimals = tokenFormat[tokenId]
        ? tokenFormat[tokenId].contract_decimals
        : 24;
      balance = !only_near
        ? formatBalanceWithDecimal(balance, tokenDecimals)
        : await handleGetNearBalance(contract);

      setBalanceOnWallet(balance);
    } catch (err) {
      console.error(err);
    }
  }, [contract, only_near, tokenId, wallet]);

  useEffect(() => {
    _handleGetUserAssetTokens();
  }, [_handleGetUserAssetTokens]);

  useEffect(() => {
    _initCalculate();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [_initCalculate]);

  return (
    <tr className="wrap-pool">
      <td className="token-item">
        <div className="icon">
          <img
            className="icon"
            src={icon}
            width={30}
            height={30}
            alt={tokenSymbol}
          />
          <span className="symbol" title={item}>
            <a
              href={`${nearWalletUrl}/accounts/${item}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tokenSymbol}
            </a>
          </span>
        </div>
      </td>
      <td>
        <div className="balance">
          <div className="balance">
            <div className="near-amount">
              {formatCashToView(
                formatBalanceWithDecimal(
                  balanceOnWallet?.toString() || "0",
                  tokenContractDecimals + tokenExtraDecimals
                ),
                8
              )}
            </div>
            <div className="fiat-amount">
              $
              {formatCashToView(
                formatBalanceWithDecimal(
                  (tokenUsd * balanceOnWallet)?.toString() || "0",
                  tokenContractDecimals + tokenExtraDecimals
                ),
                8
              )}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default TokenItem;
