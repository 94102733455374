import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import { AppContext } from "Contexts/AppContext";
import { TTokenFormat } from "types/token";
import { countMinimumDollarOfTokenList } from "utils/common";
import { QUERY_KEY } from "utils/constant";

import BalanceDepositItem from "components/myBalance/balance-deposit/BalanceDepositItem";

export default function BalanceDeposit() {
  const queryClient = useQueryClient();
  const { profile, poolTokenList } = useContext(AppContext);

  const [isShowDepositList, setIsShowDepositList] = useState(true);
  const [data, setData] = useState([]);

  // const [paginSetting, setPaginSetting] = useState({
  //   total: 50,
  //   current: 1,
  // });

  // const handlePaginOnchange: PaginationProps["onChange"] = (page) => {
  //   setPaginSetting((prev) => ({ ...prev, current: page }));
  // };

  const handleShowDepositList = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const lengthLowerMinimumDollar = countMinimumDollarOfTokenList(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    if (profile?.userBalance?.supplied.length === lengthLowerMinimumDollar) {
      setData([]);
      setIsShowDepositList(false);
    } else {
      setData(profile?.userBalance?.supplied);
    }
  }, [poolTokenList, profile?.userBalance?.supplied, queryClient]);

  useEffect(() => {
    handleShowDepositList();
  }, [handleShowDepositList]);

  return (
    <div className="deposit-tab">
      <h3 className="big-title">
        <span style={{ color: "#49E454" }}>Deposited</span> assets
      </h3>
      <div className="token-list">
        <div className="side">
          <div className="detail">
            <div className="label label__title">
              <p className="title">Asset</p>
              <p className="title">Rewards</p>
              <p className="title">Deposited</p>
              <p className="title">APY</p>
              {/* <p className="title">Deposited Interest</p> */}
              <p className="title">Actions</p>
            </div>
            {isShowDepositList ? (
              data?.map((item: any, idx: number) => {
                return (
                  item.token_id && (
                    <BalanceDepositItem key={idx} currentToken={item} />
                  )
                );
              })
            ) : (
              <div className="empty-account-line">
                <p>
                  Not Available. Go to <Link to="/app">Deposit</Link> now !
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <NearPagination
    current={paginSetting?.current}
    total={paginSetting?.total}
    paginOnchange={handlePaginOnchange}
  /> */}
    </div>
  );
}
