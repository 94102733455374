import { IPoolNftList } from "utils/interfaces";

const PoolNftList: IPoolNftList = {
  poolNftList: null,
  setPoolNftList: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

export default PoolNftList;
