import React, { Fragment } from "react";
import { CollectionComponent } from "components/vaults/common";

export const TitleModal = (props: any) => {
  const { nftsData } = props;
  return (
    <div className="title-box">
      <h3 className="title">My NFTs</h3>
      <div className="des">Available NFTs: {nftsData?.length}</div>
    </div>
  );
};

export const BodyModal = (props: any) => {
  const { onSubmitDeposit, nftsData } = props;

  return (
    <div className="body-vault-modal">
      {nftsData.length > 0 ? (
        <Fragment>
          <div className="label-top flex-style ">
            <div className="body-label">collection</div>
            <div className="body-label">action</div>
          </div>
          <div className="list-content">
            {nftsData.map((item: any, idx: number) => {
              return (
                <div key={item?.token_id + Math.random()} className="item-tag">
                  <div className="flex-style">
                    <CollectionComponent
                      imgUrl={item?.metadata.media}
                      label={item?.metadata.title ?? "lang biang"}
                      id={item?.token_id}
                    />
                    <div>
                      <div
                        onClick={() => onSubmitDeposit(item)}
                        className="vaults-btn"
                      >
                        deposit
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Fragment>
      ) : (
        <h3 className="body-vault-modal-na">Not Available</h3>
      )}
    </div>
  );
};
