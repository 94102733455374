import { Modal } from "antd";
import styled from "styled-components";
import "./style.scss";

export const NearModal = styled(Modal)`
  color: whitesmoke;
  .ant-modal-content,
  .ant-modal-header {
    background-color: #23262f;
  }
  .ant-modal-close {
    color: #777e91;
  }
  .ant-modal-header {
    border-bottom: 1px solid #353945;
    padding: 16px 0;
  }
  .ant-modal-content {
    padding: 0 25px;
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 24px 0;
  }
`;
