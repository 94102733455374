import { AppContext } from "Contexts/AppContext";
import React, { useContext } from "react";
import { ENV_ID_MAIN_CONTRACT } from "utils/constant";

// type TProps = {
//   mess?: string;
// };

const LoginRequest = () => {
  const { wallet } = useContext(AppContext);

  const login = () => {
    wallet.requestSignIn(ENV_ID_MAIN_CONTRACT, "Rust Counter Example");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        fontSize: 20,
        height: "calc(100vh - 224px)",
      }}
    >
      <p onClick={login}>
        Please <span className="n-highlight">connect</span> wallet!
      </p>
    </div>
  );
};

export default LoginRequest;
