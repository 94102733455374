import styled from "styled-components";
import { COLORs } from "utils/colors";

interface LineProps {
  style?: object;
  height?: number;
  isFlat?: boolean;
}
interface Props {
  height?: number;
  isflat?: boolean;
}

const Lines = (props: LineProps) => {
  const { height, isFlat } = props;
  return (
    <div {...props}>
      <PathFade>
        <Circle />
        <Line height={height} isflat={isFlat} />
      </PathFade>
    </div>
  );
};

export default Lines;

const PathFade = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  border: 1px solid ${COLORs.GREEN_LIGHT};
  position: relative;
`;

const Line = styled.div<Props>`
  width: 1px;
  height: ${(props) => (props.height ? `${props.height}px` : "300px")};
  background-image: ${(props) =>
    props.isflat
      ? `linear-gradient(
    to right,
    ${COLORs.GREEN_LINE},
    ${COLORs.GREEN_LINE}
  )`
      : `linear-gradient(
    to bottom,
    rgba(73, 228, 84, 0.5),
    rgba(73, 228, 84, 0)
  )`};
`;
