import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { handleWithdrawNft } from "utils/connect/contract";
import {
  getTotalBalanceNftPriceUSD,
  getTotalBalanceTokenPriceUSD,
} from "utils/common";
import { AppContext } from "Contexts/AppContext";
import { EQueryKey, TTokenFormat } from "types/token";
import iconClose from "images/icon-close.png";
import icon_nel from "images/token_nel.png";
import warning_icon from "images/warning_icon.png";
import useCurrentNft from "hooks/useCurrentNft";
import { MAX_HEALTH_FACTOR_PERCENT } from "utils/constant";
const { GET_FORMAT_TOKEN } = EQueryKey;
type Props = {
  togglePopup: () => void;
  currentToken: any;
};
const WithdrawNft = ({ togglePopup, currentToken }: Props) => {
  const queryClient = useQueryClient();
  const getNewFormatToken = queryClient.getQueryData(
    GET_FORMAT_TOKEN
  ) as unknown as TTokenFormat;

  const { contract, profile, poolTokenList, poolNftList }: any =
    useContext(AppContext);

  const {
    nft_contract_id,
    nft_token_id,
    tokenName,
    tokenIcon,
    tokenUsdPriceRatio,
    tokenUsdPrice,
  } = useCurrentNft(currentToken);

  const test = useCurrentNft(currentToken);
  console.log({ test });

  const [currentHealthFactor, setCurrentHealFactor] = useState(0);

  const _handleWithdraw = useCallback(() => {
    if (currentHealthFactor < 100) return;
    return handleWithdrawNft(contract, nft_contract_id, nft_token_id);
  }, [contract, currentHealthFactor, nft_contract_id, nft_token_id]);

  const initInfoHealthFactor = useCallback(() => {
    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const nft_collateral_to_usd = getTotalBalanceNftPriceUSD(
      profile?.userBalance?.nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    const total_supplied = collateral_to_usd + nft_collateral_to_usd;
    const total_borrowed = borrow_to_usd + tokenUsdPriceRatio;

    const healFactor =
      (total_supplied / (total_borrowed === 0 ? 1 : total_borrowed)) * 100;

    setCurrentHealFactor(Number(healFactor));
  }, [
    getNewFormatToken,
    poolNftList,
    poolTokenList,
    tokenUsdPriceRatio,
    profile,
  ]);

  const checkHealthFactorColor = useMemo(() => {
    if (currentHealthFactor < 200) return "orange";
    else return "green";
  }, [currentHealthFactor]);

  const showHealthFactorPercent = useMemo(() => {
    if (currentHealthFactor > 10000) return MAX_HEALTH_FACTOR_PERCENT;
    return `${currentHealthFactor.toFixed(4).slice(0, -2)}%`;
  }, [currentHealthFactor]);

  useEffect(() => {
    initInfoHealthFactor();
  }, [initInfoHealthFactor]);

  return (
    <div className={`wrap-popup`}>
      <div className="popup">
        <p className="icon-close" onClick={togglePopup}>
          <img alt="icon-close" src={iconClose} width={12} height={12} />
        </p>
        <h4 className={`title yellow`}>Withdraw</h4>
        <div className="wrap-icon-name wrap-icon-name-nft">
          <p className="icon">
            <img
              className="icon"
              src={tokenIcon || icon_nel}
              width={54}
              height={54}
              alt="Logo"
            />
          </p>
          <div className="wrap-price-apy">
            <div className="wrap-price">
              <p className="icon-name">{tokenName}</p>
              <p className="price">#1</p>
              {/* <p className="price">Floor price: ${tokenUsdPrice}</p> */}
            </div>
            <div className="wrap-nft-health">
              <h3 className="nft-health-title">Health Factor</h3>
              <h3 className={`nft-health-number ${checkHealthFactorColor}`}>
                {showHealthFactorPercent}
              </h3>
            </div>
          </div>
        </div>

        {currentHealthFactor < 100 && (
          <div className="wrap-warning-nft">
            <p className="icon">
              <img
                className="icon"
                src={warning_icon}
                width={24}
                height={24}
                alt="Warning icon"
              />
            </p>
            <p className="txt">
              Your estimated Heath Factor is lower than 100%. You are not
              available to withdraw asset.
            </p>
          </div>
        )}

        <div className="bg-white position-relative wrap-white">
          <button
            className={`position-relative btn yellow ${
              currentHealthFactor < 100 && "disable"
            }`}
            onClick={_handleWithdraw}
          >
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawNft;
