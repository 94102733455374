import "../../styles/LangBiang.scss";
import React, { useEffect } from "react";
import AOS from "aos";
import LangbiangJoining from "./LangbiangJoining";
import LangbiangFooter from "./LangbiangFooter";
import LangbiangBanner from "./LangbiangBanner";
import LangbiangBenefit from "./LangbiangBenefit";
import { useWindowDimensions } from "hooks/useWindowSize";
import { mobileBreakPoint } from "utils/constant";

export default function LangBiangClub() {
  const { width } = useWindowDimensions();

  useEffect(() => {
    AOS.init({
      duration: 1500,
      disable: width < mobileBreakPoint,
    });
    return () => AOS.refresh();
  }, [width]);

  return (
    <div className="container-all">
      <div className="lang-biang-club">
        <LangbiangBanner />
        <LangbiangBenefit />
        <LangbiangJoining />
        <LangbiangFooter />
      </div>
    </div>
  );
}
