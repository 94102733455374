type Props = {
  setTurnOff: Function;
  tokenId?: string;
  token?: any;
};
const CommingSoon = ({ setTurnOff }: Props) => {
  const _handleTurnOff = () => {
    setTurnOff();
  };

  return (
    <div className="wrap-popup">
      <div className="popup notification">
        <h4 className="title-notification">Comming Soon ! 💻 </h4>
        <button className="button-notification button-gray" onClick={_handleTurnOff}>Close</button>
      </div>
    </div>
  );
};

export default CommingSoon;
