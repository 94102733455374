import { AppContext } from "Contexts/AppContext";
import { useContext } from "react";
import { useQueryClient } from "react-query";
import { QUERY_KEY, tokenFormat as tokenFormatDefault } from "utils/constant";

const useCurrentToken = (current_token: any) => {
  const { poolTokenList } = useContext(AppContext);
  const queryClient = useQueryClient();
  const tokenFormat = queryClient.getQueryData(
    QUERY_KEY.GET_FORMAT_TOKEN
  ) as unknown as any;

  const tokensAPY = queryClient.getQueryData("GET_TOKEN_APY") as unknown as any;

  // Check to get current token in Pool
  const currentTokenInPool =
    current_token.borrow_apr || current_token.supply_apr
      ? current_token
      : poolTokenList?.find(
          (t: any) => (t.token_id || t.tokenId) === current_token.token_id
        );

  const tokenRatio = currentTokenInPool?.config?.volatility_ratio;
  const tokenId: string =
    currentTokenInPool?.tokenId || currentTokenInPool?.token_id;
  const suppliedBalance = currentTokenInPool?.supplied.balance;
  const borrowedBalance = currentTokenInPool?.borrowed.balance;
  const tokenDepositBalance = currentTokenInPool?.balance;

  const apy = tokensAPY?.find(
    (apy) => apy?.token_id === currentTokenInPool?.token_id
  ) || { supply_apr: 0, borrow_apr: 0 };
  const { supply_apr, borrow_apr } = apy;

  const tokenConfig = tokenFormat?.[tokenId] || tokenFormatDefault;
  const tokenIcon: string = tokenConfig?.icon;
  const tokenName: string = tokenConfig?.name;
  const tokenNameUsd = tokenConfig?.nameUsd;
  const tokenSymbol = tokenConfig && tokenConfig?.symbol;
  const tokenDecimals = tokenConfig && tokenConfig?.decimals;
  const tokenContractDecimals =
    tokenConfig && tokenConfig?.contract_decimals
      ? tokenConfig?.contract_decimals
      : 0;
  const tokenExtraDecimals =
    tokenConfig && tokenConfig?.extra_decimals
      ? tokenConfig?.extra_decimals
      : 0;
  const tokenUsdPrice = tokenConfig.usd ?? 23;

  return {
    tokenId,
    tokenName,
    tokenNameUsd,
    tokenIcon,
    tokenSymbol,
    tokenUsdPrice,
    tokenDecimals,
    tokenExtraDecimals,
    tokenContractDecimals,
    tokenDepositBalance,
    tokenConfig,
    tokenRatio,
    currentTokenInPool,
    suppliedBalance,
    borrowedBalance,
    supply_apr,
    borrow_apr,
  };
};

export default useCurrentToken;
