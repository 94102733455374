import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Logo } from "components/common";
import SocialFooter from "components/common/SocialFooter";
import { DebuggerContext } from "components/debug/FlagDebugger";
import { AppContext } from "Contexts/AppContext";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { routeManager } from "routes";
import { formatAccountName } from "utils/common";
import {
  AuctionUrl,
  ENV_ID_MAIN_CONTRACT,
  mobileBreakPoint,
  pathCollection,
  ROUTE_COLLECTION,
} from "utils/constant";
import "./style.scss";
import { useWindowDimensions } from "hooks/useWindowSize";

export default function Header() {
  const { isUrlDebugger, queryString } = useContext(DebuggerContext);
  const { setAuthParams, isLoggedIn, account, wallet } = useContext(AppContext);

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= mobileBreakPoint, [width]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { pathname } = useLocation();
  const menuWithoutLandingPage = pathCollection.includes(pathname);

  const login = () => {
    wallet.requestSignIn(ENV_ID_MAIN_CONTRACT, "Rust Counter Example");
  };

  const logout = async () => {
    await wallet.signOut();
    setAuthParams((prev: any) => ({
      ...prev,
      isLoggedIn: false,
      account: {
        ...prev.account,
        accountName: "",
      },
    }));
    // history({
    //   pathname: ROUTE_COLLECTION.LANDING,
    //   search: isUrlDebugger ? "?debug=1" : "",
    // });
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    const elementTopMenu: Element | null =
      window.document.getElementById("wrap-header-menu");
    if (window.scrollY >= 10) {
      elementTopMenu && elementTopMenu.classList.add("active");
    } else {
      elementTopMenu && elementTopMenu.classList.remove("active");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.document
        .getElementsByTagName("html")[0]
        .classList.remove("nav-open");
      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const ConnectWalletComp = () => (
    <div
      className={`menu-btn button-style menus  ${
        isUrlDebugger ? "" : "non-login"
      } `}
    >
      <span className="btn-connect" onClick={login}>
        <span className="btn-label">Connect Wallet</span>
      </span>
    </div>
  );

  const MobileSubItem = () => (
    <>
      {isMobile && (
        <>
          {isLoggedIn ? (
            <>
              <span
                className="mobile-only-responsive menu child-menu logged-menu"
                onClick={() => setIsMenuOpen(false)}
              >
                <NavLink
                  to={{
                    pathname: ROUTE_COLLECTION.VIEW_ACCOUNT,
                    search: isUrlDebugger ? queryString : "",
                  }}
                >
                  View Account
                </NavLink>
              </span>
              <span
                onClick={login}
                className="mobile-only-responsive sign-out menu child-menu logged-menu"
                style={{ fontWeight: 700 }}
              >
                Switch Wallet
              </span>
              <span
                onClick={logout}
                className="mobile-only-responsive sign-out menu child-menu logged-menu"
                style={{ fontWeight: 700 }}
              >
                Sign Out
              </span>
            </>
          ) : (
            <ConnectWalletComp />
          )}
        </>
      )}
    </>
  );

  return (
    <header id="wrap-header-menu" className="header">
      <div className="container container-header">
        <div className="wrapper-logo-header">
          <div className="mobile-responsive-logo">
            <Logo />
          </div>
          {isMobile && (
            <div className="hamburger" onClick={handleOpenMenu}>
              <MenuOutlined />
            </div>
          )}
        </div>
        <nav
          className={`${isMenuOpen ? "active" : ""} mobile-responsive-navbar`}
        >
          <div className="mobile-only-responsive logo-mobile-responsive">
            <div className="wrapper-close-ico" onClick={handleOpenMenu}>
              <CloseOutlined style={{ fontSize: 25 }} />
            </div>
            <div onClick={() => setIsMenuOpen(false)}>
              <Logo />
            </div>
          </div>
          <div className="nav-menu">
            {!menuWithoutLandingPage && (
              <>
                <div className="menus mobile-sub-item">
                  <span
                    className="mobile-only-responsive menu child-menu logged-menu"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <NavLink
                      to={{
                        pathname: ROUTE_COLLECTION.APP,
                        search: isUrlDebugger ? queryString : "",
                      }}
                    >
                      Launch App
                    </NavLink>
                  </span>
                </div>
                <div className="homepage-social">
                  <SocialFooter />
                </div>
              </>
            )}
            {menuWithoutLandingPage && (
              <div className="menus without-homepage">
                {routeManager.loggedIn.urls.map((item, idx) => (
                  <Fragment key={idx}>
                    <span
                      key={idx}
                      className="menu child-menu logged-menu child-item"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {item?.blank ? (
                        <a
                          className="link-auction"
                          href={AuctionUrl}
                          rel="noreferrer"
                          target={"_blank"}
                        >
                          {item?.label}
                        </a>
                      ) : (
                        <NavLink
                          to={{
                            pathname: item?.pathName,
                            search: isUrlDebugger ? queryString : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "link-active" : ""
                          }
                        >
                          {item?.label}
                        </NavLink>
                      )}
                    </span>
                    {idx > routeManager.loggedIn.urls.length - 2 ? null : (
                      <span className="light child-menu child-item" />
                    )}
                  </Fragment>
                ))}
                <MobileSubItem />
              </div>
            )}
          </div>
          {menuWithoutLandingPage && (
            <div className="mobile-only-responsive mobile-menu-bottom">
              <SocialFooter />
            </div>
          )}
        </nav>
        {menuWithoutLandingPage && (
          <div
            className={`${
              isMenuOpen ? "active" : ""
            } mobile-responsive-navbar right-wallet-btn`}
          >
            {isLoggedIn ? (
              <>
                <div className={`menu-btn button-style menus`}>
                  <span className="btn-connect">
                    <span className="btn-label">
                      {formatAccountName(account?.accountName)}
                    </span>
                    <div className="more btn-header">
                      <div className="wrap-toggle">
                        <NavLink
                          to={{
                            pathname: ROUTE_COLLECTION.VIEW_ACCOUNT,
                            search: isUrlDebugger ? queryString : "",
                          }}
                        >
                          View Account
                        </NavLink>

                        <span onClick={login} className="sign-out">
                          Switch Wallet
                        </span>
                        <span onClick={logout} className="sign-out">
                          Sign Out
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </>
            ) : (
              <ConnectWalletComp />
            )}
          </div>
        )}
      </div>
    </header>
  );
}
