import { useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BackTop } from "antd";
import backtoTop from "images/backtop.svg";

import Main from "components/main";
import AppContextProvider, { AppContext } from "Contexts/AppContext";
import { routeManager } from "routes";
import { AppInitWrapper } from "Contexts";
import Image from "components/common/Images";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppContextProvider>
          <Main>
            <AppInitWrapper>
              <RouteManager />
            </AppInitWrapper>
            <BackTop>
              <Image src={backtoTop} />
            </BackTop>
          </Main>
        </AppContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

const RouteManager = () => {
  const { isLoggedIn } = useContext(AppContext);
  const mappingRoute = isLoggedIn
    ? routeManager.loggedIn.components
    : routeManager.noneLoggedIn.components;

  return (
    <Routes>
      {mappingRoute.map(({ path, element }, idx: number) => {
        return <Route key={idx} path={path} element={element} />;
      })}
    </Routes>
  );
};
