import { AppContext } from "Contexts/AppContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { handleGetMetadataNft } from "utils/connect/contract";
import {
  ENV_ID_TOKEN_wNEAR,
  QUERY_KEY,
  tokenFormat as tokenFormatDefault,
} from "utils/constant";
import useImageExist from "./useImageExist";

const useCurrentNft = (current_token: any) => {
  const { poolNftList, contract } = useContext(AppContext);
  const queryClient = useQueryClient();
  const tokenFormat = queryClient.getQueryData(
    QUERY_KEY.GET_FORMAT_TOKEN
  ) as unknown as any;
  const tokenFormatNear = tokenFormat[ENV_ID_TOKEN_wNEAR];

  const [data, setData] = useState<any>({});
  const nft_token_id = current_token.token_id || current_token.nft_token_id;
  // Check to get current token in Pool
  const currentTokenInPool =
    current_token.borrow_apr || current_token.supply_apr
      ? current_token
      : poolNftList?.find(
          (t: any) =>
            (t.token_id || t.tokenId) ===
            (current_token.contractName || current_token.nft_contract_id)
        );

  const tokenRatio = currentTokenInPool?.config?.volatility_ratio / 10000 || 1;
  const nft_contract_id: string =
    current_token.contractName ||
    current_token.nft_contract_id ||
    currentTokenInPool?.tokenId ||
    currentTokenInPool?.token_id;

  // const suppliedBalance = currentTokenInPool?.supplied.balance;
  // const borrowedBalance = currentTokenInPool?.borrowed.balance;
  // const tokenDepositBalance = currentTokenInPool?.balance;
  // const tokenAPY = currentTokenInPool?.apy || 0;
  // const { borrowAPY } = getBorrowAndSupplyAPY(currentTokenInPool);

  const tokenConfig = tokenFormat?.[nft_contract_id] || tokenFormatDefault;
  const { image: tokenIcon } = useImageExist({ url: data?.metadata?.media });
  const tokenName: string = tokenFormat?.[nft_contract_id].name;
  const tokenUsdPrice = tokenFormat?.[currentTokenInPool.token_id].usd;
  const tokenUsdPriceRatio =
    tokenFormat?.[currentTokenInPool.token_id].usd * tokenRatio;

  const tokenNearPrice = Number(tokenUsdPrice / tokenFormatNear.usd);
  const nftMetadata = data?.metadata || {};

  const _initData = useCallback(async () => {
    const initData = await handleGetMetadataNft(
      contract,
      nft_contract_id,
      nft_token_id
    );

    initData && setData(initData);
  }, [contract, nft_contract_id, nft_token_id]);

  useEffect(() => {
    _initData();
  }, [_initData]);

  return {
    nft_token_id,
    nft_contract_id,
    tokenName,
    // tokenNameUsd,
    tokenIcon,
    // tokenSymbol,
    tokenUsdPrice,
    tokenUsdPriceRatio,
    tokenNearPrice,
    // tokenDecimals,
    tokenConfig,
    tokenRatio,
    currentTokenInPool,
    nftMetadata,
    // tokenAPY,
    // borrowAPY,
    // tokenDepositBalance,
    // suppliedBalance,
    // borrowedBalance,
  };
};

export default useCurrentNft;
