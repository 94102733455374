import React, { useState, createContext } from "react";
import { ChildrenProps, IAppContext } from "utils/interfaces";
import { TPoolTokenList } from "utils/types";
import {
  Auth,
  UserBalance,
  ContractMain,
  Wallet,
  PoolTokenList,
  PoolNftList,
} from "Contexts/init";
import { Spin } from "antd";
import nel_reward from "images/nel-reward.png";
import { routeManager } from "routes";

export const AppContext = createContext<IAppContext | any>({
  ...Auth,
  ...UserBalance,
  ...ContractMain,
  ...Wallet,
  ...PoolTokenList,
  ...PoolNftList,
});

const AppContextProvider = ({ children }: ChildrenProps) => {
  const [authParams, setAuthParams] = useState(Auth);
  const [profile, setUserProfile] = useState(UserBalance);
  const [contract, setContract] = useState(ContractMain);
  const [wallet, setWallet] = useState(Wallet);
  const [poolTokenList, setPoolTokenList] = useState<TPoolTokenList[]>();
  const [poolNftList, setPoolNftList] = useState<TPoolTokenList[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [mainMenu, setMainMenu] = useState(routeManager);

  return (
    <AppContext.Provider
      value={{
        ...authParams,
        ...profile,
        contract,
        wallet,
        poolTokenList,
        poolNftList,
        mainMenu,
        setAuthParams,
        setUserProfile,
        setContract,
        setWallet,
        setPoolTokenList,
        setPoolNftList,
        setIsLoading,
        setMainMenu,
      }}
    >
      <Spin
        size="small"
        spinning={isLoading}
        indicator={
          <div>
            <img
              className="loading-spin-icon"
              src={nel_reward}
              alt="loading"
              width={125}
              height={125}
            />
            <p className="loading-text">Loading...</p>
          </div>
        }
        wrapperClassName="loading-spin-wrapper"
      >
        {children}
      </Spin>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
