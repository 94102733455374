import styled from "styled-components";
import { RelativeWrapper, Title } from "./styles";
import { WrappAnimated } from "components/common";
import { Container } from "components/common/Container";
import Image from "components/common/Images";

import CogitentIco from "images/investors/cogitent.png";
import AntisocialIco from "images/investors/antisocial.png";
import BullishIco from "images/investors/bullish.png";
import Neartracker from "images/investors/neartracker.png";
import gridBanner from "images/banner/grid-banner.svg";

function InvesterUI() {
  return (
    <CustomWrapper className="mobile-invester-only">
      <Container style={{ overflow: "hidden" }}>
        <InvesterSpace>
          <WrappAnimated style={{ marginTop: 75 }}>
            <Title
              style={{ marginBottom: 30 }}
              data-aos="fade-down"
              className="mobile-invester-title-only"
            >
              <span className="high-light">Investors</span> & Partners
            </Title>
            <FlexStyle>
              {investorList.map((item: any, idx: number) => (
                <InvestorCard
                  key={item.key}
                  data-aos="fade-up"
                  data-aos-delay={idx * 100}
                  className="mobile-investor-list-only"
                >
                  <Image src={item.src} />
                </InvestorCard>
              ))}
            </FlexStyle>
          </WrappAnimated>
        </InvesterSpace>
      </Container>
    </CustomWrapper>
  );
}

export default InvesterUI;

const investorList = [
  {
    key: 1,
    src: CogitentIco,
  },
  {
    key: 2,
    src: Neartracker,
  },
  {
    key: 3,
    src: AntisocialIco,
  },
  {
    key: 4,
    src: BullishIco,
  },
];

const FlexStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InvestorCard = styled.div`
  max-width: 250px;
  width: 100%;
  position: relative;
  padding: 20px;
`;

const CustomWrapper = styled(RelativeWrapper)`
  margin-top: 100px;
  &::before {
    content: "";
    position: absolute;
    background-image: url(${gridBanner});
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
`;

const InvesterSpace = styled.div`
  height: 100%;
  padding: 2rem 0 5rem 0;
  width: 100%;
  text-align: center;
  position: relative;
  /* &::before {
    content: "";
    position: absolute;
    top: 7%;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: rgb(3, 22, 21);
    background: radial-gradient(
      circle,
      rgba(3, 22, 21, 1) 0%,
      rgba(66, 157, 42, 0.25) 0%,
      rgba(13, 43, 3, 0) 44%
    );
  } */
`;
