import * as nearAPI from "near-api-js";
import { useCallback, useEffect, useState } from "react";
import {
  ENV_HELPER_URL,
  ENV_ID_NETWORK,
  ENV_NODE_URL,
  ENV_WALLET_URL,
} from "utils/constant";
const NETWORK_ID = "testnet";

const { connect, keyStores, KeyPair } = nearAPI;

const contractId = "nearlend-official.testnet";

export default function useNearDev(contract_id = contractId) {
  const [nearAccounts, setNearAccount] = useState(null);

  const accounts = {
    account_id: contract_id,
  };

  const init = useCallback(async () => {
    const keyStore = new keyStores.InMemoryKeyStore();

    let accountPromises = async () => {
      const credentials = JSON.parse(
        JSON.stringify({
          account_id: contract_id,
          public_key: "ed25519:EGs51riA17iPn7F2GHESnxpqqw8wuYkivQLKk65mDJyt",
          private_key:
            "ed25519:4xz78XzTebxButVZbf7RfGW9K4EoG5kctNnK8ZJnbXtNYqGkZTc15ZEYABCnpNEtJXbKxvp4wxbaC2SP3zVcuHHx",
        })
      );
      return await keyStore.setKey(
        NETWORK_ID,
        accounts.account_id,
        KeyPair.fromString(credentials.private_key)
      );
    };

    await accountPromises();

    const nearConfig = {
      headers: {},
      deps: {
        keyStore,
      },
      networkId: ENV_ID_NETWORK,
      nodeUrl: ENV_NODE_URL,
      walletUrl: ENV_WALLET_URL,
      helperUrl: ENV_HELPER_URL,
    };

    const nearConnect = await connect(nearConfig);
    const get_account = await nearConnect.account(accounts.account_id);
    setNearAccount(get_account);
  }, [accounts.account_id, contract_id]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    nearAccounts,
  };
}
