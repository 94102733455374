import { Link } from "react-router-dom";
import { useContext } from "react";
import styled, { keyframes } from "styled-components";

import { WrapperBtn } from "components/homepage/styles";
import Image from "components/common/Images";
import { DebuggerContext } from "components/debug/FlagDebugger";

import nearText from "images/nearTextLogo.png";
import nearIcon from "images/nearIcontext.png";
import logo from "images/logo.png";

export function BuildOn() {
  return (
    <LinkWrapper href="https://near.org/" target="_blank" rel="noreferrer">
      <WrappCustom>
        Built on
        <WrapperBtn style={{ gap: 10 }}>
          <Image width={20} src={nearIcon} />
          <Image width={40} src={nearText} />
        </WrapperBtn>
      </WrappCustom>
    </LinkWrapper>
  );
}

interface LogoProps {
  gap?: string;
  isCustom?: boolean;
}

export const Logo = (props: LogoProps) => {
  const { isUrlDebugger, queryString } = useContext(DebuggerContext);

  const { gap, isCustom = false } = props;

  const styleFunc: unknown = {
    gap: gap ? `${gap}px` : 20,
    display: "flex",
    alignItems: isCustom ? "center" : "stretch",
    flexDirection: isCustom ? "column" : "row",
  };
  return (
    <WrappLogo
      to={{
        pathname: "/",
        search: isUrlDebugger ? queryString : "",
      }}
    >
      {isCustom ? (
        <div className="logo-wrapper-ico" style={styleFunc}>
          <div className="logo-wrapper-ico-child">
            <img alt="Nearlend" src={logo} width={95} height="auto" />
          </div>
          <TextLogo className="brand-name">
            <p className="brand custom">Nearlend</p>
            <p className="dao custom">Dao</p>
          </TextLogo>
        </div>
      ) : (
        <div className="logo-wrapper-ico" style={styleFunc}>
          <div className="logo-wrapper-ico-child">
            <img alt="Nearlend" src={logo} width={60} height="auto" />
          </div>
          <TextLogo className="brand-name">
            <p className="brand">Nearlend</p>
            <p className="dao">Dao</p>
          </TextLogo>
        </div>
      )}
    </WrappLogo>
  );
};

const WrappLogo = styled(Link)`
  align-items: center;
`;
const TextLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  p {
    font-weight: 700;
  }
  .brand {
    font-size: 20px;
  }
  .dao {
    font-size: 18px;
    text-transform: uppercase;
  }
  .custom {
    font-size: 14px;
  }
  /* .origin {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-bottom: 0;
      line-height: 26px;
    }
    .dao {
      font-size: 14px;
    }
    .brand {
      font-size: 24px;
    }
  } */
`;

const LinkWrapper = styled.a`
  text-decoration: none;
  color: white;
  opacity: 0.6;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1;
    color: white;
  }
`;

const WrappCustom = styled(WrapperBtn)`
  gap: 10px;
`;

const animatebottom = keyframes`
from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
`;

export const WrappAnimated = styled.section`
  animation-name: ${animatebottom};
  animation-duration: 0.5s;
  position: relative;
`;
