import React from "react";
import LangbiangHeader from "./LangbiangHeader";
import cover_bg from "images/langbiang/langbiang-hero-banner.png";

const LangbiangBanner = () => {
  return (
    <div className="top-container">
      <div className="cover-bg">
        <img src={cover_bg} alt="cover" />
      </div>
      <div className="banner-content">
        <LangbiangHeader />
        <div data-aos="fade-up">
          <div className="welcome">
            <h4>Welcome to the</h4>
            <h2>lang biang club</h2>
            <p className="description">
              The Lang Biang collection includes 333 NFTs based on a set of{" "}
              <br />
              unique masks inspired by traditional Vietnamese art. Each
              <br />
              mask represents a distinct special meaning and stored on the
              <br /> NEAR blockchain.
            </p>
            <a
              className="btn-discord-now"
              href="https://discord.gg/pXvHhT9rwM"
              target="_blank"
              rel="noreferrer"
            >
              Discover Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LangbiangBanner;
