import { useContext } from "react";
import { AppContext } from "Contexts/AppContext";
import { ENV_ID_MAIN_CONTRACT } from "utils/constant";

type Props = {
  tokenId?: string;
  token?: any;
  textTitle?: string;
  textConfirm?: string;
  textCancel?: string;
  togglePopup?: any;
  handleConfirm?: any;
};

const RequireLogin = ({
  togglePopup,
  textTitle,
  textConfirm,
  textCancel,
  handleConfirm,
}: Props) => {
  const { wallet }: any = useContext(AppContext);

  const login = () => {
    wallet.requestSignIn(ENV_ID_MAIN_CONTRACT, "Hello Nearlend !");
  };
  const _handleTogglePopup = () => {
    togglePopup();
  };

  return (
    <div className="wrap-popup">
      <div className="popup notification">
        <h4 className="text-notification">
          {`${textTitle ?? "You need to Login to deposit or borrow !"} `}
        </h4>
        <div className="wrap-button">
          <button
            className="button-notification button-gray"
            onClick={_handleTogglePopup}
          >
            {`${textCancel ?? "Close"} `}
          </button>
          <button
            className="button-notification"
            onClick={handleConfirm ?? login}
          >
            {`${textConfirm ?? "Login"} `}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequireLogin;
