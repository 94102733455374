import { IUserbalance } from "utils/interfaces";

const UserBalance: IUserbalance = {
  profile: {
    userBalance: null,
    userAssetFarms: {
      supplied: [],
      borrowed: [],
    },
  },
  setUserProfile: (): void => {
    throw new Error("setContext function must be overridden");
  },
};
export default UserBalance;
