import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import useCurrentToken from "hooks/useCurrentToken";
import { handleWithdraw } from "utils/connect/contract";
import { ACTION, QUERY_KEY } from "utils/constant";
import {
  errorPipeline,
  getAvailableLiquidityToken,
  getBorrowMaxAmount,
  getTotalBalanceNftPriceUSD,
  getTotalBalanceTokenPriceUSD,
  getWithdrawMaxAmount,
  validatePopup,
} from "utils/common";
import ActionPopupView from "components/popup/component/ViewActionPopup";
import { AppContext } from "Contexts/AppContext";
import { EQueryKey, TTokenFormat } from "types/token";
import useError from "hooks/useError";
const { GET_FORMAT_TOKEN } = EQueryKey;
type Props = {
  togglePopup: () => void;
  currentToken: any;
};
const Withdraw = ({ togglePopup, currentToken }: Props) => {
  let initInterval: any = useRef(null);

  const queryClient = useQueryClient();
  const {
    tokenName,
    tokenId,
    tokenIcon,
    tokenSymbol,
    tokenRatio,
    tokenContractDecimals,
    tokenExtraDecimals,
    supply_apr,
  } = useCurrentToken(currentToken);
  const { errorApproveTransaction } = useError();

  const { contract, profile, poolTokenList, poolNftList }: any =
    useContext(AppContext);

  const [amountToken, setAmountToken] = useState(0);
  const [available, setAvailable] = useState(0);
  const [tokenUsdPrice, setTokenUsdPrice] = useState(0);
  const [error, setError] = useState("");
  const [maxWithdrawAmount, setMaxWithdrawAmount] = useState(0);
  const [availableLiquidity, setAvailableLiquidity] = useState(0);

  const [currentHealthFactor, setCurrentHealFactor] = useState(0);
  const [borrowedState, setBorrowedState] = useState(0);
  const [collateralState, setCollateralState] = useState(0);

  const _validateError = useCallback(() => {
    const _error = errorPipeline(
      validatePopup(ACTION.WITHDRAW.toLowerCase(), amountToken, available)
    );
    setError(_error);
  }, [amountToken, available]);

  const _handleWithdraw = useCallback(async () => {
    if (error.length > 0 || amountToken === 0) return;
    _validateError();
    const errorApprove = await errorApproveTransaction();
    if (errorApprove) return setError(errorApprove);
    return handleWithdraw(currentToken, amountToken, contract);
  }, [
    amountToken,
    _validateError,
    contract,
    currentToken,
    error.length,
    errorApproveTransaction,
  ]);

  const _onChange = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const _onChangeSlider = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const initInfoHealthFactor = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const nft_to_usd = getTotalBalanceNftPriceUSD(
      profile?.userBalance?.nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    const healFactor =
      ((collateral_to_usd + nft_to_usd) /
        (borrow_to_usd === 0 ? 1 : borrow_to_usd)) *
      100;

    setCurrentHealFactor(healFactor);
    setBorrowedState(borrow_to_usd);
    setCollateralState(collateral_to_usd + nft_to_usd);
  }, [
    poolNftList,
    poolTokenList,
    profile?.userBalance?.borrowed,
    profile?.userBalance?.nft_supplied,
    profile?.userBalance?.supplied,
    queryClient,
  ]);

  const _calculate = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    if (!getNewFormatToken) return;

    const usdToken: number | undefined = getNewFormatToken?.[tokenId]?.usd;

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const nft_to_usd = getTotalBalanceNftPriceUSD(
      profile?.userBalance?.nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    const maxBorrowAmount = getBorrowMaxAmount(
      collateral_to_usd,
      borrow_to_usd,
      nft_to_usd,
      tokenRatio / 10000,
      usdToken
    );

    let availableLiquidity = getAvailableLiquidityToken(
      currentToken,
      getNewFormatToken
    );

    let withdrawMaxAmount = getWithdrawMaxAmount(
      profile?.userBalance,
      currentToken,
      tokenContractDecimals + tokenExtraDecimals
    );

    setTokenUsdPrice(usdToken);
    setAvailableLiquidity(availableLiquidity);
    setMaxWithdrawAmount(Math.min(maxBorrowAmount, withdrawMaxAmount));
    initInfoHealthFactor();
  }, [
    currentToken,
    initInfoHealthFactor,
    poolNftList,
    poolTokenList,
    profile?.userBalance,
    queryClient,
    tokenContractDecimals,
    tokenExtraDecimals,
    tokenId,
    tokenRatio,
  ]);

  const _initCalculate = useCallback(() => {
    initInterval.current = setInterval(_calculate, 600);
  }, [_calculate]);

  const _initGetAvailable = useCallback(() => {
    const available = Math.min(
      Math.max(0, maxWithdrawAmount),
      availableLiquidity
    );

    setAvailable(available);
  }, [availableLiquidity, maxWithdrawAmount]);

  useEffect(() => {
    _initCalculate();
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_initCalculate]);

  useEffect(() => {
    _initGetAvailable();
  }, [_initGetAvailable]);

  useEffect(() => {
    initInfoHealthFactor();
  }, [initInfoHealthFactor]);

  useEffect(() => {
    _validateError();
  }, [_validateError]);

  return (
    <ActionPopupView
      isYellow={true}
      textTitle={ACTION.WITHDRAW}
      togglePopup={togglePopup}
      onChange={_onChange}
      onChangeSlider={_onChangeSlider}
      confirmPopUp={_handleWithdraw}
      error={error}
      valAPY={supply_apr}
      currentHealthFactor={currentHealthFactor}
      borrowed={borrowedState}
      collateral={collateralState}
      poolAvailable={availableLiquidity}
      currentToken={{
        available,
        tokenName,
        tokenSymbol,
        tokenUsdPrice,
        tokenIcon,
      }}
      tokenRatio={tokenRatio}
    />
  );
};

export default Withdraw;
