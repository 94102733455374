import { IPoolTokenList } from "utils/interfaces";

const PoolTokenList: IPoolTokenList = {
  poolTokenList: null,
  setPoolTokenList: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

export default PoolTokenList;
