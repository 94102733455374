import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useLocation } from "react-router";
import { ChildrenProps } from "utils/interfaces";

type AppContextInterface = {
  isUrlDebugger?: boolean;
  queryString?: string;
  setDebugParams: Dispatch<SetStateAction<AppContextInterface>>;
};
const initialContext: AppContextInterface = {
  isUrlDebugger: false, //enable debug mode
  queryString: "",
  setDebugParams: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

export const DebuggerContext =
  createContext<AppContextInterface>(initialContext);

const DEBUG_PARAM = {
  DEBUGLABEL: "debug",
  FLAG: "1",
};

const FlagDebugger = ({ children }: ChildrenProps) => {
  const { search, pathname } = useLocation();

  const [debugParams, setDebugParams] =
    useState<AppContextInterface>(initialContext);

  useEffect(() => {
    const result = parseParams(search);
    if (result && result?.debug === DEBUG_PARAM.FLAG) {
      setDebugParams((prev) => ({
        ...prev,
        isUrlDebugger: true,
        queryString: search,
      }));
      return;
    }
    setDebugParams((prev) => ({
      ...prev,
      isUrlDebugger: true,
      queryString: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <DebuggerContext.Provider value={{ ...debugParams, setDebugParams }}>
      {children}
    </DebuggerContext.Provider>
  );
};

export default FlagDebugger;

//Param Extractor
const parseParams = (params = "") => {
  const rawParams = params.replace("?", "").split("&");
  const extractedParams: any = {};
  rawParams.forEach((item: any) => {
    item = item.split("=");
    extractedParams[item[0]] = item[1];
  });
  return extractedParams;
};
