import styled from "styled-components";
import { COLORs } from "utils/colors";

export const TopWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 50px;
  text-transform: capitalize;
  position: relative;
  z-index: 2;
  .high-light {
    font-weight: 600;
    color: ${COLORs.GREEN_ROW};
  }
`;

export const BackGroundText = styled.span`
  font-family: sans-serif;
  font-size: 16rem;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${COLORs.GREEN_LINE};
  line-height: 0;
  font-weight: 900;
  text-transform: capitalize;
  background: linear-gradient(
    0deg,
    rgba(73, 228, 84, 0.1) 20.83%,
    rgba(73, 228, 84, 0) 83.06%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  white-space: nowrap;
  opacity: 0.7;
`;

export const TextPosition = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 1;
  opacity: 0.5;
`;

export const VerticalLine = styled.div`
  position: absolute;
  height: 100%;
  max-height: 350px;
  border: 0.3px solid;
  border-image: linear-gradient(to bottom, #49e454, rgba(73, 228, 84, 0)) 1 100%;
  top: -25%;
  left: 17%;
`;

export const MiddleSpace = styled.div`
  height: 200px;
  position: relative;
`;

export const Position = styled.div`
  position: absolute;
  z-index: 1;
`;

export const TagNft = styled.span`
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(84, 169, 67, 0.4) 0%,
    rgba(84, 169, 67, 0) 100%
  );
  background: linear-gradient(
      180deg,
      rgba(84, 169, 67, 0) 0%,
      rgba(84, 169, 67, 0.3) 100%
    ),
    rgba(12, 73, 0, 0.4);
  backdrop-filter: blur(4px);
`;

export const Caption = styled.div`
  font-size: 42px;
  line-height: 140%;
  text-transform: capitalize;
  margin: 30px 0;
  p {
    font-weight: 700;
  }
  .open-source {
    color: ${COLORs.GREEN_LIGHT};
    font-weight: 700;
    display: inline-block;
  }
`;

export const WrapperBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
  flex-wrap: wrap;
`;

export const MainWrapper = styled.main`
  position: relative;
  color: white;
`;

export const JoinBtn = styled.button`
  cursor: pointer;
  border: 1px solid #49e454;
  background: rgba(35, 173, 47, 0.1);
  border-radius: 6px;
  letter-spacing: 1px;
  padding: 2px;
  position: relative;
  text-transform: capitalize;
  z-index: 2;
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in-out;
  span {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: background 0.5s ease;
    width: 100%;
    color: white;
    font-weight: 700;
    font-size: 15px;
  }
  svg {
    width: 13px;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 2px 2px 15px 1px #54a943;
  }
`;
export const LauchBtn = styled.button`
  cursor: pointer;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #23ad2f;
  border-radius: 6px;
  letter-spacing: 1px;
  padding: 2px;
  border: 1px solid #49e454;
  position: relative;
  text-transform: capitalize;
  z-index: 2;
  width: 160px;
  height: 50px;
  transition: all 0.1s ease-in-out;
  span {
    font-size: 15px;
    align-items: center;

    display: flex;
    justify-content: center;
    height: 100%;
    transition: background 0.5s ease;
    width: 100%;
    color: white;
    font-weight: 700;
  }
  &:hover {
    box-shadow: 2px 2px 15px 1px #54a943;
  }
`;
