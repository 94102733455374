import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import {
  handleGetUserTokenBalance,
  handleDeposit,
} from "utils/connect/contract";
import useCurrentToken from "hooks/useCurrentToken";
import {
  errorPipeline,
  getBorrowAndSupplyAPY,
  getTotalBalanceNftPriceUSD,
  getTotalBalanceTokenPriceUSD,
  validatePopup,
} from "utils/common";
import { EQueryKey, TTokenFormat } from "types/token";
import ActionPopupView from "components/popup/component/ViewActionPopup";
import { AppContext } from "Contexts/AppContext";
import { ACTION, MINIMUM_DOLLAR_IS_ALLOW } from "utils/constant";
import useHealthFactor from "hooks/useHealthFactor";
import useError from "hooks/useError";

const { GET_FORMAT_TOKEN } = EQueryKey;

type Props = {
  togglePopup: () => void;
  currentToken: any;
};
const Deposit = ({ togglePopup, currentToken }: Props) => {
  const initInterval = useRef<any>();
  const {
    tokenId,
    tokenName,
    tokenDecimals = 0,
    tokenContractDecimals = 0,
    tokenSymbol,
    tokenConfig,
    tokenIcon,
    tokenRatio,
    supply_apr,
  } = useCurrentToken(currentToken);
  const { profile, wallet, contract, poolTokenList, poolNftList } =
    useContext(AppContext);

  const { currentHealthFactor } = useHealthFactor();
  const { errorApproveTransaction } = useError();
  // React query
  const queryClient = useQueryClient();

  let { supplyAPY } = getBorrowAndSupplyAPY(currentToken);
  if (!supplyAPY && currentToken?.apy !== undefined) {
    supplyAPY = currentToken?.apy;
  }

  const [amountToken, setAmountToken] = useState(0);
  const [available, setAvailable] = useState<number>();
  const [error, setError] = useState("");
  const [tokenUsdPrice, setTokenUsdPrice] = useState<number>();
  const [borrowedState, setBorrowedState] = useState(0);
  const [collateralState, setCollateralState] = useState(0);

  const _validateError = useCallback(() => {
    const _error = errorPipeline(
      validatePopup(ACTION.DEPOSIT.toLowerCase(), amountToken, available)
    );
    setError(_error);
  }, [amountToken, available]);

  const _handleDeposit = useCallback(async () => {
    if (
      amountToken * tokenUsdPrice < MINIMUM_DOLLAR_IS_ALLOW ||
      error.length > 0
    )
      return;
    _validateError();
    const errorApprove = await errorApproveTransaction();
    if (errorApprove) return setError(errorApprove);

    return handleDeposit(currentToken, amountToken, contract);
  }, [
    errorApproveTransaction,
    _validateError,
    amountToken,
    contract,
    currentToken,
    error.length,
    tokenUsdPrice,
  ]);

  const _onChange = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const _onChangeSlider = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const initInfoHealthFactor = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const nft_to_usd = getTotalBalanceNftPriceUSD(
      profile?.userBalance?.nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    setBorrowedState(borrow_to_usd);
    setCollateralState(collateral_to_usd + nft_to_usd);
  }, [
    poolNftList,
    poolTokenList,
    profile?.userBalance?.borrowed,
    profile?.userBalance?.nft_supplied,
    profile?.userBalance?.supplied,
    queryClient,
  ]);

  const _initGetAvailable = useCallback(async () => {
    try {
      const balance = await handleGetUserTokenBalance(
        tokenId,
        tokenContractDecimals,
        contract,
        wallet
      );
      setAvailable(balance);
    } catch (err) {
      console.error(err);
      setAvailable(0);
    }
  }, [contract, tokenContractDecimals, tokenId, wallet]);

  const _calculate = useCallback(() => {
    const { usd } = tokenConfig || { usd: 1 };
    setTokenUsdPrice(usd);
  }, [tokenConfig]);

  const _initCalculate = useCallback(() => {
    initInterval.current = setInterval(_calculate, 400);
  }, [_calculate]);

  useEffect(() => {
    _initCalculate();
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_initCalculate]);

  useEffect(() => {
    _initGetAvailable();
  }, [_initGetAvailable]);

  useEffect(() => {
    initInfoHealthFactor();
  }, [initInfoHealthFactor]);

  useEffect(() => {
    _validateError();
  }, [_validateError]);

  return (
    <ActionPopupView
      textTitle={ACTION.DEPOSIT}
      togglePopup={togglePopup}
      onChange={_onChange}
      onChangeSlider={_onChangeSlider}
      confirmPopUp={_handleDeposit}
      error={error}
      valAPY={supply_apr}
      borrowed={borrowedState}
      collateral={collateralState}
      tokenRatio={tokenRatio}
      tokenDecimals={tokenDecimals}
      currentHealthFactor={currentHealthFactor}
      currentToken={{
        available,
        tokenName,
        tokenSymbol,
        tokenUsdPrice,
        tokenIcon,
      }}
    />
  );
};

export default Deposit;
