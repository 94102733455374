import "./style.scss";
import { Container } from "components/common/Container";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "Contexts/AppContext";

import { Rewards } from "components/liquidity/components/Reward";
import { RewardOtherToken } from "components/liquidity/components/RewardOtherToken";
import { RequireLogin } from "components/popup";

import { SUPPORTED_TOKENS, tokenFormat } from "utils/constant";
import {
  GAS,
  handleRegisterToken,
  isUserRegisterToken,
} from "utils/connect/contract";
import { notification } from "antd";
import LoginRequest from "components/common/LoginRequest";
import SocialFooter from "components/common/SocialFooter";

export const Liquidity = () => {
  const navigate = useNavigate();
  const intervalRef = useRef<any>();
  const {
    wallet,
    contract,
    account,
    setUserProfile,
    setIsLoading,
    isLoggedIn,
    profile: {
      userAssetFarms: { borrowed, supplied },
    },
  } = useContext(AppContext);

  const [tokenRegister, setTokenRegister] = useState("");
  const [isShowRegister, setIsShowRegister] = useState(false);

  const _handleToggleRegister = () => {
    setIsShowRegister(!isShowRegister);
  };

  const _openNotification = () => {
    notification.success({
      style: { top: 80 },
      message: "Claim successfully ! 🚀",
      description: "Please ! Check the assets at your wallet !",
    });
  };

  const _getUserBalance = useCallback(async () => {
    if (!contract || !account.accountId) return;
    try {
      const user = await contract.get_account({
        account_id: account.accountId,
      });

      if (!user) return;

      const {
        account_id,
        borrowed,
        has_non_farmed_assets,
        nft_supplied,
        supplied,
      } = user || {};

      const farmSupplyAssets = user.farms
        .filter((item) => item.farm_id.Supplied)
        .map((item) => ({ ...item, farm_id: item.farm_id.Supplied }));

      const farmBorrowAssets = user.farms
        .filter((item) => item.farm_id.Borrowed)
        .map((item) => ({ ...item, farm_id: item.farm_id.Borrowed }));

      setUserProfile((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          userBalance: {
            account_id,
            borrowed,
            has_non_farmed_assets,
            nft_supplied,
            supplied,
          },
          userAssetFarms: {
            ...prev.profile.userAssetFarms,
            supplied: farmSupplyAssets,
            borrowed: farmBorrowAssets,
          },
        },
      }));
    } catch (error) {
      console.log(error);
    }
  }, [account, contract, setUserProfile]);

  const _actionClaimAll = async () => {
    setIsLoading(true);
    const res = await contract.account.functionCall(
      contract.contractId,
      "account_farm_claim_all",
      {},
      GAS
    );
    if (res.status.hasOwnProperty("SuccessValue")) {
      _getUserBalance();
      setIsLoading(false);
      _openNotification();
    }
  };

  const _checkValidBeforeClaimAll = async () => {
    const list_check_token = SUPPORTED_TOKENS;

    let token_need_to_register = "";
    for (const token_id of list_check_token) {
      const check_token = await isUserRegisterToken(contract, wallet, token_id)
        .then((res) => {
          if (!res) return token_id;
          return "";
        })
        .catch((e) => {
          return "";
        });

      if (check_token) {
        token_need_to_register = check_token;
        break;
      }
    }

    if (token_need_to_register) {
      setTokenRegister(token_need_to_register);
      _handleToggleRegister();
    } else {
      await _actionClaimAll();
    }
    return token_need_to_register;
  };

  const _handleClaimAll = async () => {
    try {
      await _checkValidBeforeClaimAll();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const _goToDeposit = () => navigate("/app");

  const getRewardRealtime = useCallback(async () => {
    intervalRef.current = setInterval(_getUserBalance, 2000);
  }, [_getUserBalance]);

  const _handleRegisterToken = async () => {
    if (!tokenRegister) return;
    await handleRegisterToken(contract, wallet, tokenRegister);
  };

  useEffect(() => {
    getRewardRealtime();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [getRewardRealtime, _getUserBalance]);

  return (
    <div className="liquidation-wrapper flex-vertical">
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <Container>
          {isLoggedIn ? (
            <>
              {borrowed.length || supplied.length ? (
                <button
                  className={`claim-all-button common-button btn`}
                  onClick={_handleClaimAll}
                >
                  Claim All
                </button>
              ) : (
                <button
                  className={`claim-all-button common-button btn`}
                  onClick={_goToDeposit}
                >
                  Go to Deposit
                </button>
              )}
              <div className="liquidity-wrap">
                <div className="liquidity-side">
                  <Rewards />
                </div>
                <div className="liquidity-side liquidity-side-right">
                  <RewardOtherToken />
                </div>
              </div>
              {isShowRegister && (
                <RequireLogin
                  textTitle={`Register "${
                    tokenFormat[tokenRegister]?.symbol || tokenRegister
                  } token" before claims all !`}
                  textConfirm={"Accept"}
                  textCancel={"Cancel"}
                  togglePopup={_handleToggleRegister}
                  handleConfirm={_handleRegisterToken}
                />
              )}
            </>
          ) : (
            <LoginRequest />
          )}
        </Container>
      </div>
      <SocialFooter />
    </div>
  );
};
