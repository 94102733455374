import { Pagination } from "antd";
import "./style.scss";

interface PaginProps {
  total: number;
  current: number;
  paginOnchange: (page: number, pageSize: number) => void;
}

const NearPagination = (props: PaginProps) => {
  const { total, current, paginOnchange } = props;
  return (
    <div className="near-pagination">
      <Pagination
        total={total}
        current={current}
        onChange={paginOnchange}
        showSizeChanger={false}
      />
    </div>
  );
};

export default NearPagination;
