const useLocalStore = () => {
  const setLocalStore = (key, value) => {
    return localStorage.setItem(key, value);
  };

  const getLocalStore = (key) => {
    return localStorage.getItem(key);
  };

  return { getLocalStore, setLocalStore };
};

export default useLocalStore;
