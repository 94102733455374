import useCurrentToken from "hooks/useCurrentToken";
import { useMemo } from "react";
import { formatBalanceWithDecimal } from "utils/common";
import { MINIMUM_DOLLAR_IS_ALLOW } from "utils/constant";

export default function useMinimumDollar(
  current_token,
  minimum_of_dollar = MINIMUM_DOLLAR_IS_ALLOW
) {
  const { tokenUsdPrice, tokenExtraDecimals, tokenContractDecimals } =
    useCurrentToken(current_token);

  const isAllowToShow = useMemo(() => {
    return (
      formatBalanceWithDecimal(
        current_token.balance,
        tokenExtraDecimals + tokenContractDecimals
      ) *
        tokenUsdPrice >
        minimum_of_dollar || false
    );
  }, [
    current_token.balance,
    minimum_of_dollar,
    tokenContractDecimals,
    tokenExtraDecimals,
    tokenUsdPrice,
  ]);

  return isAllowToShow;
}
