import React from "react";
import join1 from "images/join1.png";
import join2 from "images/join2.png";
import join3 from "images/join3.png";
import thuyen from "images/langbiang/ship.png";
import NftsCollection from "./NftsCollection";
import { Container } from "components/common/Container";

const Joining = () => {
  return (
    <div className="join">
      <Container>
        <div className="join-ntfcollection">
          <NftsCollection />
        </div>
        <div className="joining-wrapper" data-aos="zoom-in">
          <span className="title">
            <span className="how-join">How to join the</span>
            <span className="lang-biang">Lang Biang Club</span>
          </span>
          <div className="guides-box">
            {guides.map((item: any, idx: number) => {
              return (
                <a href={item?.ref} key={idx} target="_blank" rel="noreferrer">
                  <div className="guide" data-aos="flip-left">
                    <div className="guide-wrapper">
                      <img src={item.url} alt={item.alt} />
                      <div className="guide-des">
                        <p className="idx">{item.idx}</p>
                        <p className="desc">{item.desc}</p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
          <div className="boat" data-aos="fade-right">
            <img src={thuyen} alt="ship" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Joining;

const guides = [
  {
    idx: "01",
    url: join1,
    alt: "join1",
    desc: `Buy     Lang Biang`,
    ref: "https://marketplace-v2-testnet.paras.id/collection/nft.nearlend-official.testnet",
  },
  {
    idx: "02",
    url: join2,
    alt: "join2",
    desc: "Go to NFT Linker",
    ref: "https://nftlinker.app/",
  },
  {
    idx: "03",
    url: join3,
    alt: "join3",
    desc: "Become part of Nearlend DAO",
    ref: "http://discord.gg/ZvzPuZRGWp",
  },
];
