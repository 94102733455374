export interface IToken {
  tokenId: string;
  supplied: IBorrowed;
  borrowed: IBorrowed;
  reserved: string;
  last_update_timestamp: string;
  config: IConfig;
  supply_apr: string;
  borrow_apr: string;
}

export interface IBorrowed {
  shares: string;
  balance: string;
}

export interface IConfig {
  reserve_ratio: number;
  target_utilization: number;
  target_utilization_rate: string;
  max_utilization_rate: string;
  volatility_ratio: number;
  extra_decimals: number;
  can_deposit: boolean;
  can_withdraw: boolean;
  can_use_as_collateral: boolean;
  can_borrow: boolean;
}

export type TTokenFormat = {
  [token_id: string]: {
    extra_decimals: number;
    contract_decimals: number;
    icon: string;
    name: string;
    nameUsd: string;
    usd: number;
    symbol: string;
  };
};

export type TUSDPriceToken = {
  [tokenName: string]: { usd: number };
};

export enum EQueryKey {
  GET_FORMAT_TOKEN = "GET_FORMAT_TOKEN",
  GET_TOKEN_PRICE_WITH_NAME = "GET_TOKEN_PRICE_WITH_NAME",
}
// export type TNewFormatToken = {
//   [anyName]: {
//     decimals: number;
//     icon: string;
//     name: string;
//     nameUsd: string;
//     price: number;
//     symbol: string;
//   };
// };
// export type TTokenPrice = {
//   [anyName]: {
//     usd: number;
//   };
// };
