import aurora from "images/token/aurora.jpeg";
import nel from "images/token_nel.png";
import near from "images/token/near.png";
import usdt from "images/token/usdt.png";
import weth from "images/token/weth.png";
import { TTokenFormat } from "types/token";

// export const ENV_ID_TOKEN_NEL = process.env.REACT_APP_TOKEN_NEL;
export const ENV_ID_ORACLE_CONTRACT = process.env.REACT_APP_ID_ORACLE_CONTRACT;
export const ENV_ID_MAIN_CONTRACT = process.env.REACT_APP_ID_MAIN_CONTRACT;
export const ENV_NODE_URL = process.env.REACT_APP_NODE_URL;
export const ENV_WALLET_URL = process.env.REACT_APP_WALLET_URL;
export const ENV_HELPER_URL = process.env.REACT_APP_HELPER_URL;
export const ENV_EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL;
export const ENV_ID_NETWORK = process.env.REACT_APP_ID_NETWORK;

export const ENV_ID_TOKEN_NEL = process.env.REACT_APP_ID_TOKEN_NEL;
export const ENV_ID_TOKEN_ETH = process.env.REACT_APP_ID_TOKEN_ETH;
export const ENV_ID_TOKEN_wETH = process.env.REACT_APP_ID_TOKEN_wETH;
export const ENV_ID_TOKEN_DAI = process.env.REACT_APP_ID_TOKEN_DAI;
export const ENV_ID_TOKEN_USDT = process.env.REACT_APP_ID_TOKEN_USDT;
export const ENV_ID_TOKEN_wNEAR = process.env.REACT_APP_ID_TOKEN_wNEAR;
export const ENV_ID_TOKEN_stNEAR = process.env.REACT_APP_ID_TOKEN_stNEAR;
export const ENV_ID_TOKEN_REF = process.env.REACT_APP_ID_TOKEN_REF;
export const ENV_ID_NFT_LANGBIANG = process.env.REACT_APP_ID_NFT_LANGBIANG;

export const MAX_HEALTH_FACTOR_PERCENT = `${9999}%`;
export const LAST_DECIMAL_REPLACE = 9;
export const FORMAT_CASH_DECIMAL = 13;
export const MINIMUM_DOLLAR_IS_ALLOW = 1;
export const NEAR_APPROVE_TRANSACTION = 0.25;

export const TEXT = {
  REGISTER: "Register",
  LOG_IN: "Login",
  CANCEL: "Cancel",
  CLAIM: "Claim",
};

export const ERROR = {
  ACTION_INVALID: "Error! Not available to perform action!",
  AMOUNT_TOKEN_INVALID: "Error! Please enter amounts of token!",
  APPROVE_TRANSACTION: `Error! Need ${NEAR_APPROVE_TRANSACTION} NEAR to approve !`,
};
export const errorAvailable = (action_type) =>
  `Error! Insufficient available to ${action_type}!`;

export const tokenFormat: TTokenFormat = {
  [ENV_ID_TOKEN_REF]: {
    nameUsd: "ref-finance",
    name: "ref-finance",
    symbol: "REF",
    icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='16 24 248 248' style='background: %23000'%3E%3Cpath d='M164,164v52h52Zm-45-45,20.4,20.4,20.6-20.6V81H119Zm0,18.39V216h41V137.19l-20.6,20.6ZM166.5,81H164v33.81l26.16-26.17A40.29,40.29,0,0,0,166.5,81ZM72,153.19V216h43V133.4l-11.6-11.61Zm0-18.38,31.4-31.4L115,115V81H72ZM207,121.5h0a40.29,40.29,0,0,0-7.64-23.66L164,133.19V162h2.5A40.5,40.5,0,0,0,207,121.5Z' fill='%23fff'/%3E%3Cpath d='M189 72l27 27V72h-27z' fill='%2300c08b'/%3E%3C/svg%3E%0A",
    extra_decimals: 0,
    contract_decimals: 6,
    usd: 1,
  },
  [ENV_ID_TOKEN_wETH]: {
    nameUsd: "ethereum",
    name: "ethereum",
    symbol: "wETH",
    icon: weth,
    extra_decimals: 0,
    contract_decimals: 18,
    usd: 1,
  },
  [ENV_ID_TOKEN_DAI]: {
    nameUsd: "Dai",
    name: "Dai",
    symbol: "DAI",
    icon: "data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23F4B731' fill-rule='nonzero' cx='16' cy='16' r='16'/%3E%3Cpath d='M9.277 8h6.552c3.985 0 7.006 2.116 8.13 5.194H26v1.861h-1.611c.031.294.047.594.047.898v.046c0 .342-.02.68-.06 1.01H26v1.86h-2.08C22.767 21.905 19.77 24 15.83 24H9.277v-5.131H7v-1.86h2.277v-1.954H7v-1.86h2.277V8zm1.831 10.869v3.462h4.72c2.914 0 5.078-1.387 6.085-3.462H11.108zm11.366-1.86H11.108v-1.954h11.37c.041.307.063.622.063.944v.045c0 .329-.023.65-.067.964zM15.83 9.665c2.926 0 5.097 1.424 6.098 3.528h-10.82V9.666h4.72z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E",
    extra_decimals: 0,
    contract_decimals: 18,
    usd: 1,
  },
  [ENV_ID_TOKEN_USDT]: {
    nameUsd: "Tether USD",
    name: "Tether USD",
    symbol: "USDT",
    icon: usdt,
    extra_decimals: 12,
    contract_decimals: 6,
    usd: 1,
  },
  [ENV_ID_TOKEN_wNEAR]: {
    nameUsd: "Wrapped Near",
    name: "Wrapped Near",
    symbol: "wNEAR",
    icon: near,
    extra_decimals: 0,
    contract_decimals: 24,
    usd: 1,
  },
  [ENV_ID_TOKEN_stNEAR]: {
    nameUsd: "Staked NEAR",
    name: "Staked NEAR",
    symbol: "stNEAR",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/18754.png",
    extra_decimals: 0,
    contract_decimals: 24,
    usd: 1,
  },
  [ENV_ID_NFT_LANGBIANG]: {
    nameUsd: "Lang biang",
    name: "Lang biang",
    symbol: "Nft",
    icon: near,
    extra_decimals: 0,
    contract_decimals: 24,
    usd: 1,
  },
  [ENV_ID_TOKEN_NEL]: {
    nameUsd: "Nel token",
    name: "Nel token",
    symbol: "NEL",
    icon: nel,
    extra_decimals: 0,
    contract_decimals: 24,
    usd: 1,
  },
  "aurorax.testnet": {
    nameUsd: "aurora",
    name: "aurora",
    symbol: "Aurora",
    icon: aurora,
    extra_decimals: 0,
    contract_decimals: 18,
    usd: 1,
  },
};

export const ACTION = {
  DEPOSIT: "Deposit",
  BORROW: "Borrow",
  REPAY: "Repay",
  WITHDRAW: "Withdraw",
};

export const SUPPORTED_TOKENS = [
  ENV_ID_TOKEN_wETH,
  ENV_ID_TOKEN_DAI,
  ENV_ID_TOKEN_USDT,
  ENV_ID_TOKEN_wNEAR,
  ENV_ID_TOKEN_stNEAR,
  ENV_ID_TOKEN_NEL,
];
export const SUPPORTED_NFT = [ENV_ID_NFT_LANGBIANG];

export const QUERY_KEY = {
  GET_FORMAT_TOKEN: "GET_FORMAT_TOKEN",
  GET_TOKEN_PRICE_WITH_NAME: "GET_TOKEN_PRICE_WITH_NAME",
};

export const LL = "YYYY-MM-DD";
export const LLL = "D MMM YYYY - HH:mm";
export const LLLL = "D MMMM YYYY";

export const ROUTE_COLLECTION = {
  LANDING: "/",
  APP: "/app",
  MARKET_PLACE: "/marketplace",
  VAULTS: "/vaults",
  BALANCE: "/balance",
  LIQUIDITY: "/liquidity",
  AUCTION_HOUSE: "/auction-house",
  WHITE_PAPER: "/white-paper",
  PICKDECK: "/pitch-deck",
  LANGBIANG_CLUB: "/lang-biang-club",
  VIEW_ACCOUNT: "/asset",
};

export const AuctionUrl = "https://testnet.auction.nearlenddao.com/";
export const PitchDeckUrl = "https://documents.nearlenddao.com/pitch-desk.pdf";
export const WhitePaperUrl = "https://documents.nearlenddao.com/whitepaper.pdf";

export const pathCollection = [
  ROUTE_COLLECTION.APP,
  ROUTE_COLLECTION.VAULTS,
  ROUTE_COLLECTION.BALANCE,
  ROUTE_COLLECTION.PICKDECK,
  ROUTE_COLLECTION.LIQUIDITY,
  ROUTE_COLLECTION.WHITE_PAPER,
  ROUTE_COLLECTION.MARKET_PLACE,
  ROUTE_COLLECTION.AUCTION_HOUSE,
  ROUTE_COLLECTION.LANGBIANG_CLUB,
  ROUTE_COLLECTION.VIEW_ACCOUNT,
];

export const exceptHeaderPath = [ROUTE_COLLECTION.LANGBIANG_CLUB];
export const landingViewWithoutGettingPrice = [
  ROUTE_COLLECTION.LANGBIANG_CLUB,
  ROUTE_COLLECTION.LANDING,
];

export const mobileBreakPoint = 768;
