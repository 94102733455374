import React from "react";
import "styles/Asset.scss";
import AssetTokenList from "components/asset/AssetTokenList";
import AssetNftList from "components/asset/AssetNftList";

function Asset() {
  return (
    <main id="asset-page" className="bg-main">
      <div className="container homepage flex-vertical asset">
        <div className="content">
          <h2>Your Asset</h2>
          <div className="tbl-asset">
            <div className="assetFt">
              <h2 className="tbl-title">
                <span>Token</span>
              </h2>
              <table>
                <thead>
                  <th>Asset</th>
                  <th>Amount</th>
                </thead>
                <tbody>
                  <AssetTokenList />
                </tbody>
              </table>
            </div>
            <div className="assetNft">
              <h2 className="tbl-title">
                <span>NFT</span>
              </h2>
              <table>
                <thead>
                  <th>Asset</th>
                  <th>AVG floor price (7D)</th>
                </thead>
                <tbody>
                  <AssetNftList />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Asset;
