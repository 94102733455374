import { AppContext } from "Contexts/AppContext";
import { useContext } from "react";
import { handleDepositFirstTime } from "utils/connect/contract";

type Props = {
  setTurnOff: Function;
  tokenId?: string;
  textTitle?: string;
  token?: any;
};

const RegisterFirstTime = ({
  textTitle = "You need to register to deposit or borrow !",
  setTurnOff,
}: Props) => {
  const { contract, wallet } = useContext(AppContext);

  const _handleTurnOff = () => {
    setTurnOff();
  };
  const _handleDeposit = () => {
    handleDepositFirstTime(contract, wallet);
  };

  return (
    <div className="wrap-popup">
      <div className="popup notification">
        <h4 className="text-notification">{textTitle}</h4>
        <p className="text-notification">
          Fee: <strong>0.1 NEAR</strong>
        </p>
        <div className="wrap-button">
          <button
            className="button-notification button-gray"
            onClick={_handleTurnOff}
          >
            Close
          </button>
          <button className="button-notification" onClick={_handleDeposit}>
            Deposit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterFirstTime;
