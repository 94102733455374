import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { formatBalanceWithDecimal, formatCashToView } from "utils/common";
import {
  ENV_ID_TOKEN_wNEAR,
  MINIMUM_DOLLAR_IS_ALLOW,
  QUERY_KEY,
  tokenFormat,
} from "utils/constant";
import { TTokenFormat } from "types/token";
import useCurrentToken from "hooks/useCurrentToken";

import logo_nel_transparent from "images/logo_nel_transparent.png";
import { get_asset_farms_all } from "utils/connect/contract";
import { AppContext } from "Contexts/AppContext";

import Big from "big.js";

type TArrayReward = { [key: string]: number }[];

function TokenItem({
  item,
  actions: { openPopupDeposit, openPopupBorrow, handleTogglePopupRequire },
}: any) {
  const initInterval = useRef<any>();
  const queryClient = useQueryClient();
  const { tokenId } = item;

  const { contract } = useContext(AppContext);
  const { tokenExtraDecimals, tokenContractDecimals, supply_apr, borrow_apr } =
    useCurrentToken(item);

  const icon = tokenFormat[tokenId.toString()]?.icon;
  const tokenSymbol = tokenFormat[tokenId.toString()]?.symbol;
  const tokenDecimals: number = tokenExtraDecimals + tokenContractDecimals;
  const [tokenUsd, setTokenUsd] = useState(0);
  const [rewards, setRewards] = useState([]);
  const supplied: any =
    formatBalanceWithDecimal(item?.supplied?.balance, tokenDecimals) || 0;

  const reserved: any = formatBalanceWithDecimal(item?.reserved, tokenDecimals);

  const totalSupplied: number =
    Number(supplied?.toFixed(4).slice(0, -2)) +
    Number(reserved?.toFixed(4).slice(0, -2));

  const borrowed: any = formatBalanceWithDecimal(
    item?.borrowed.balance,
    tokenDecimals
  );

  const _calculate = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    if (!getNewFormatToken) return;
    setTokenUsd(getNewFormatToken[tokenId].usd);
  }, [queryClient, tokenId]);

  const _initCalculate = useCallback(() => {
    initInterval.current = setInterval(_calculate, 400);
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_calculate]);

  useEffect(() => {
    _initCalculate();
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_initCalculate]);

  const _getAssetFarm = useCallback(async () => {
    try {
      const rs = await get_asset_farms_all(contract);

      const getNewFormatToken = queryClient.getQueryData(
        QUERY_KEY.GET_FORMAT_TOKEN
      ) as unknown as TTokenFormat;

      const reward_by_token_id = rs.filter(
        (item) => item[0].Supplied === tokenId || item[0].Borrowed === tokenId
      );

      const _reduce_reward = (acc, curr) => {
        const obj_reward = curr[1].rewards;
        for (const property in obj_reward) {
          acc.push({
            [property]: Big(obj_reward[property].reward_per_day)
              .div(
                Big(10).pow(
                  getNewFormatToken[property].contract_decimals +
                    getNewFormatToken[property].extra_decimals
                )
              )
              .toNumber(),
          });
        }
        return acc;
      };

      const get_token_reward_by_supply: TArrayReward = reward_by_token_id
        .filter((item) => item[0].Supplied)
        .reduce(_reduce_reward, []);

      const get_token_reward_by_borrow: TArrayReward = reward_by_token_id
        .filter((item) => item[0].Borrowed)
        .reduce(_reduce_reward, []);

      const merged_token_reward: TArrayReward = [
        ...get_token_reward_by_borrow,
        ...get_token_reward_by_supply,
        // { "dai.fakes.testnet": 20000 },
        // { "dai.fakes.testnet": 40000 },
        // { "weth.fakes.testnet": 40000000 },
        // { "ft.nearlend-official.testnet": 9000 },
      ];

      const output: { [key: string]: number } = merged_token_reward.reduce(
        (acc, curr) => {
          const current_id = Object.keys(curr)[0];
          if (acc[current_id]) {
            acc[current_id] = acc[current_id] + curr[current_id];
          } else {
            acc[Object.keys(curr)[0]] = curr[current_id];
          }
          return acc;
        },
        {}
      );

      const final_reward = [];
      for (const [key, value] of Object.entries(output)) {
        if (value * getNewFormatToken[key].usd > MINIMUM_DOLLAR_IS_ALLOW) {
          final_reward.push({
            symbol_reward: getNewFormatToken[key].symbol || "N/A",
            icon_reward: getNewFormatToken[key].icon || logo_nel_transparent,
            id_reward: key,
            reward_per_day: value,
          });
        }
      }

      setRewards(final_reward);
    } catch (e) {
      setRewards([]);
      console.log(e);
    }
  }, [contract, queryClient, tokenId]);

  useEffect(() => {
    _getAssetFarm();
  }, [_getAssetFarm]);

  return (
    <div data-cy={`${tokenId}`} className="wrap-pool">
      {/* <button
        className="button-claim button-claim-outside"
        onClick={() => handleTogglePopupRequire(item)}
      >
        {tokenId === ENV_ID_TOKEN_wNEAR ? "Swap" : "Claim"}
      </button> */}
      <div className="mini asset market-flex">
        <img className="icon" src={icon} width={32} height={32} alt="Logo" />
        <div className="coin-des">
          <p className="top coin color-white fwb">{tokenSymbol}</p>
          <p className="color-space-gray">
            ${tokenUsd.toFixed(4).slice(0, -2)}
          </p>
        </div>
      </div>
      <div className="mini btn-claim hover-reward mini-custom">
        <img
          src={logo_nel_transparent}
          className="logo_nel_transparent"
          width={20}
          height={20}
          alt="logo_nel_transparent"
        />
        <div className="board-reward">
          <h5>Liquidity rewards</h5>
          {rewards.length > 0 ? (
            <div className="wrap-reward">
              {rewards.map((item, idx) => (
                <div key={idx} className="reward">
                  <div className="reward-name">
                    <img
                      className="icon"
                      src={item.icon_reward}
                      width={24}
                      height={24}
                      alt="Logo"
                    />
                    <p>{item.symbol_reward}</p>
                  </div>
                  <div className="reward-per-day">
                    <p>{formatCashToView(item.reward_per_day, 8)} / day</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <h4>Not Available !</h4>
          )}
        </div>
      </div>
      <div className="mini deposit">
        <p className="top color-white fwb text-right">
          {formatCashToView(totalSupplied, 8)}
        </p>
        <p className="color-space-gray text-right">
          $
          {formatCashToView(
            Number(totalSupplied.toFixed(4).slice(0, -2)) *
              Number(tokenUsd.toFixed(4).slice(0, -2)),
            8
          )}
        </p>
      </div>

      <div className="mini deposit on-desktop">
        <p className="top color-white fwb text-right">
          {/* {Number(formatCash(borrowed as unknown as number)).toFixed(2)} */}
          {formatCashToView(borrowed, 8)}
        </p>
        <p className="color-space-gray text-right">
          $
          {formatCashToView(
            Number(borrowed.toFixed(4).slice(0, -2)) *
              Number(tokenUsd.toFixed(4).slice(0, -2)),
            8
          )}
        </p>
      </div>
      <div
        data-cy="cy-button-deposit"
        onClick={(e) => openPopupDeposit(e, item)}
        className="action color-white on-desktop update-v2"
      >
        <div className="market-flex apy">
          <p>{supply_apr}%</p>
        </div>
        <button className="btn-base button-basic-deposit">Deposit</button>
      </div>
      <div
        onClick={(e) => openPopupBorrow(e, item)}
        className="action color-white on-desktop update-v2"
      >
        <div className="market-flex apy">
          <p>{borrow_apr}%</p>
        </div>
        <button className="btn-base button-basic-borrow">Borrow</button>
      </div>
    </div>
  );
}

export default TokenItem;
