import { useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  getTotalBalanceTokenPriceUSD,
  getTotalBalanceNftPriceUSD,
} from "utils/common";
import { EQueryKey, TTokenFormat } from "types/token";
import { AppContext } from "Contexts/AppContext";

const { GET_FORMAT_TOKEN } = EQueryKey;

const useHealthFactor = () => {
  const { profile, poolTokenList, poolNftList } = useContext(AppContext);

  const [currentHealthFactor, setCurrentHealFactor] = useState(0);

  // React query
  const queryClient = useQueryClient();

  const initInfoHealthFactor = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const nft_to_usd = getTotalBalanceNftPriceUSD(
      profile?.userBalance?.nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    const healFactor =
      ((collateral_to_usd + nft_to_usd) /
        (borrow_to_usd === 0 ? 1 : borrow_to_usd)) *
      100;

    setCurrentHealFactor(healFactor);
  }, [
    poolNftList,
    poolTokenList,
    profile?.userBalance?.borrowed,
    profile?.userBalance?.nft_supplied,
    profile?.userBalance?.supplied,
    queryClient,
  ]);

  useEffect(() => {
    initInfoHealthFactor();
  }, [initInfoHealthFactor]);

  return {
    currentHealthFactor,
  };
};

export default useHealthFactor;
