import { Card, Col, Row } from "antd";
import styled from "styled-components";

import { Container } from "components/common/Container";
import Image from "components/common/Images";
import {
  MiddleSpace,
  RelativeWrapper,
  WrapperBtn,
} from "components/homepage/styles";
import { COLORs } from "utils/colors";

import daoIco from "images/solutions/dao.png";
import flexibleIco from "images/solutions/flexible.png";
import lendingIco from "images/solutions/lending.png";
import tripleIco from "images/solutions/triple.png";

function SolutionUI() {
  return (
    <SolutionWrapper className="mobile-solution-only-wrapper">
      <Container>
        <MiddleSpace style={{ height: 100 }} />
      </Container>
      <RelativeWrapper>
        <Container className="mobile-solution-only">
          <Row
            style={{ alignItems: "stretch", rowGap: 10 }}
            gutter={16}
            data-aos="fade-left"
          >
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <CardWrapper
                style={{ height: "100%", zIndex: 2 }}
                title={
                  <CardTitle>
                    <WrapperBtn
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Image src={lendingIco} style={{ width: 85 }} />
                      <span className="title">lending 2.0</span>
                    </WrapperBtn>
                  </CardTitle>
                }
                bordered={false}
              >
                <span className="des">
                  Beside major cryptocurrency as ETH, BTC, NEAR,{" "}
                  <span className="hightLight">
                    NFTs are supported as collaterals{" "}
                  </span>
                  for borrowing others cryptocurrency & token.
                </span>
              </CardWrapper>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <CardWrapper
                style={{ height: "100%", zIndex: 2 }}
                title={
                  <CardTitle>
                    <WrapperBtn
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Image src={flexibleIco} style={{ width: 85 }} />
                      <span className="title">Variable APY</span>
                    </WrapperBtn>
                  </CardTitle>
                }
                bordered={false}
              >
                <span className="des">
                  Nearlend appies{" "}
                  <span className="hightLight">variable APY</span>, to meet the
                  need of user in the short & long term.
                </span>
              </CardWrapper>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <CardWrapper
                style={{ height: "100%", zIndex: 2 }}
                title={
                  <CardTitle>
                    <WrapperBtn
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Image src={tripleIco} style={{ width: 85 }} />
                      <span className="title">Triple Liquidation</span>
                    </WrapperBtn>
                  </CardTitle>
                }
                bordered={false}
              >
                <span className="des">
                  Mechanism ensures{" "}
                  <span className="hightLight">high safety </span>for liquidity
                  pool and{" "}
                  <span className="hightLight">attracts users/collectors </span>
                  to participate in the asset liquidation process.
                </span>
              </CardWrapper>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <CardWrapper
                style={{ height: "100%", zIndex: 2 }}
                title={
                  <CardTitle>
                    <WrapperBtn
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Image src={daoIco} style={{ width: 85 }} />
                      <span className="title">DAO Governance</span>
                    </WrapperBtn>
                  </CardTitle>
                }
                bordered={false}
              >
                <span className="des">
                  <span className="hightLight">Nearlend </span>over time, will
                  transition to <span className="hightLight">DAO</span>. DAO's
                  members will vote on high-level decisions of the protocol.
                </span>
              </CardWrapper>
            </Col>
          </Row>
        </Container>
      </RelativeWrapper>
    </SolutionWrapper>
  );
}

export default SolutionUI;

const SolutionWrapper = styled(RelativeWrapper)`
  padding-bottom: 80px;
  position: relative;
`;

const CardTitle = styled.span`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 22px;
  color: white;
  .title {
    font-weight: 600;
    font-size: 18px;
  }
`;

const CardWrapper = styled(Card)`
  background: radial-gradient(
    77.4% 82.93% at 22.6% 26.46%,
    rgba(73, 228, 84, 0.08) 0%,
    rgba(73, 228, 84, 0) 100%
  );
  backdrop-filter: blur(14px);
  border-radius: 20px;
  color: white;
  max-width: 300px;
  width: 100%;
  padding: 35px 15px 25px 15px;
  position: relative;
  transition: all 0.5s ease;
  cursor: pointer;
  .des {
    font-size: 14px;
    letter-spacing: 0.03rem;
    .hightLight {
      color: ${COLORs.GREEN_LIGHT};
    }
  }
  .ant-card-body {
    padding: 24px 20px;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-head {
    border: none;
    position: relative;
  }
  &:hover {
    transition: all 0.5s ease;
    background: linear-gradient(
        180deg,
        rgba(106, 169, 67, 0) 0%,
        rgba(106, 169, 67, 0.7) 100%
      ),
      linear-gradient(0deg, rgba(106, 169, 67, 0.3), rgba(106, 169, 67, 0.3));
    transform: translateY(-20px);
  }
`;
