import { useState } from "react";
import { shortBalance } from "utils/common";
import PopupWithdrawNft from "components/popup/actions/WithdrawNft";
import img_nel_reward from "images/nel-reward.png";
import useCurrentNft from "hooks/useCurrentNft";

export default function NFTDepositItem({ itemToken }: any) {
  const {
    tokenIcon,
    tokenUsdPrice,
    tokenUsdPriceRatio,
    tokenName,
    tokenNearPrice,
    nft_token_id,
    nftMetadata,
  } = useCurrentNft(itemToken);

  const [isShowWithdraw, setIsShowWithdraw] = useState(false);

  const _handleToggleWithdraw = () => {
    setIsShowWithdraw(!isShowWithdraw);
  };

  return (
    <div className="wrap-info active">
      <div
        className="label label__token"
        // onClick={_handleToggleDetail}
      >
        <div className="label__token-mini token__logo">
          <p className="img">
            <img
              className="icon"
              src={tokenIcon}
              width={32}
              height={32}
              alt="Logo"
            />
          </p>
          <div className="token__price">
            <p className="nft_name token_name color-space-gray">
              {nftMetadata?.title || `${tokenName} #${nft_token_id}`}
            </p>
            {/* <p className="color-whitey">${shortBalance(tokenUsdPrice)}</p> */}
          </div>
        </div>
        <div className="label__token-mini">
          <div className="token__price">
            <p className="token_name color-space-gray">
              {/* 100.21{" "} */}
              <img
                src={img_nel_reward}
                width={16}
                height={16}
                alt="Nel Rewards"
              />
            </p>
            {/* <p className="color-whitey">$23</p> */}
          </div>
        </div>
        <div className="label__token-mini">
          <div className="token__price">
            <p className="token_name color-space-gray">
              {tokenNearPrice.toFixed(2)} NEAR
            </p>
            <p className="color-whitey">${tokenUsdPrice}</p>
          </div>
          {/* <p className="color-whitey fwb">${tokenUsdPriceRatio.toFixed(2)}</p> */}
        </div>
        {/* <div className="label__token-mini">
          <p className="color-whitey fwb">
            {percentFormatter.format(tokenAPY)}
          </p>
        </div> */}
        <div className="label__token-mini label__token-mini__actions">
          <button
            onClick={_handleToggleWithdraw}
            className="button-basic button-basic-deposit bg-yellow"
          >
            Withdraw
          </button>
        </div>
      </div>
      {/* {isShowIncrease && (
        <PopupIncreaseCollateral
          togglePopup={_handleToggleIncrease}
          currentToken={itemToken}
        />
      )}
      {isShowDecrease && (
        <PopupDecreaseCollateral
          togglePopup={_handleToggleDecrease}
          currentToken={itemToken}
        />
      )} */}
      {isShowWithdraw && (
        <PopupWithdrawNft
          togglePopup={_handleToggleWithdraw}
          currentToken={itemToken}
        />
      )}
      {/* {isShowDeposit && (
        <PopupDeposit
          togglePopup={_handleToggleDeposit}
          currentToken={itemToken}
        />
      )} */}
    </div>
  );
}
