import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { ROUTE_COLLECTION } from "utils/constant";

import { ReactComponent as TwiterIco } from "images/twiter.svg";
import { ReactComponent as DiscordIco } from "images/discord.svg";
import { Logo } from "components/common";

const LangbiangHeader = () => {
  const { search } = useLocation();
  return (
    <header id="wrap-header-menu" className="header">
      <div className="container">
        <nav>
          <div className="nav-menu">
            <div className="menus">
              <Fragment>
                <a
                  className="btn-connect doc menu"
                  href={`${ROUTE_COLLECTION.LANDING}${search}`}
                >
                  <span className="desktop-only title-social">
                    Nearlend DAO
                  </span>
                  <span className="mobile-only mobile-logo-langbiang">
                    <Logo gap="10" />
                    <span>Nearlend DAO</span>
                  </span>
                </a>
                <a
                  className="btn-connect doc menu"
                  href="https://twitter.com/NearlendDao"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="desktop-only title-social">Twitter</span>
                  <span className="mobile-only">
                    <TwiterIco style={{ width: 20, height: 20 }} />
                  </span>
                </a>
                <a
                  className="btn-connect doc menu"
                  href="https://discord.gg/pXvHhT9rwM"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="desktop-only title-social">Discord</span>
                  <span className="mobile-only">
                    <DiscordIco style={{ width: 20, height: 20 }} />
                  </span>
                </a>
              </Fragment>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default LangbiangHeader;
