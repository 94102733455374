import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { PitchDeckUrl } from "utils/constant";

const PitchDeck = () => {
  let navigate: any = useNavigate();
  const _goBack = () => {
    navigate(-1);
  };
  return (
    <main
      className="white-paper"
      style={{
        position: "relative",
        zIndex: 9999,
        minHeight: "100vh",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <object
        style={{
          position: "relative",
          zIndex: 9999,
          width: "100%",
          minHeight: "100vh",
          height: "100%",
          border: "none",
        }}
        data={PitchDeckUrl}
        type="application/pdf"
      >
        <p className="pdf-not-work">
          Your web browser doesn't have a PDF plugin. Instead you can{" "}
          <Link to={PitchDeckUrl} target="_blank" download>
            click here to download the PDF file.
          </Link>
          <br />
          <br />
          <span onClick={_goBack}>
            <a href="/">{`Go back`}</a>
          </span>
        </p>
      </object>
    </main>
  );
};

export default PitchDeck;
