import Big from "big.js";
import BalanceTokenItem from "components/myBalance/components/BalanceTokenItem";
import useMinimumDollar from "components/myBalance/hooks/useMinimumDollar";
import PopupWithdraw from "components/popup/actions/Withdraw";
import { AppContext } from "Contexts/AppContext";
import useCurrentToken from "hooks/useCurrentToken";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import {
  ENV_ID_TOKEN_NEL,
  LAST_DECIMAL_REPLACE,
  QUERY_KEY,
} from "utils/constant";

export default function BalanceDepositItem({ currentToken }: any) {
  const queryClient = useQueryClient();
  const isMounted = useRef(true);
  const refGetPriceRealtime = useRef<any>();

  const { profile } = useContext(AppContext);
  const {
    currentTokenInPool,
    tokenIcon,
    tokenSymbol,
    tokenExtraDecimals = 0,
    tokenContractDecimals = 0,
    supply_apr,
    tokenId,
  } = useCurrentToken(currentToken);
  const isAllowToShow = useMinimumDollar(currentToken);
  const [isShowWithdraw, setIsShowWithdraw] = useState(false);
  const [usd, setUsd] = useState(0);
  const [currentReward, setCurrentReward] = useState<number>();

  const _handleToggleWithdraw = () => {
    setIsShowWithdraw(!isShowWithdraw);
  };

  const _getPriceRealtime = useCallback(() => {
    const tokenFormat = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as any;
    setUsd(tokenFormat?.[tokenId]?.usd || 0);
  }, [queryClient, tokenId]);

  const _initGetPriceRealtime = useCallback(() => {
    refGetPriceRealtime.current = setInterval(_getPriceRealtime, 4000);
  }, [_getPriceRealtime]);

  const _initReward = useCallback(() => {
    const reward_supply = profile.userAssetFarms.supplied.find(
      (item) => item.farm_id === tokenId
    );

    const nel_reward = reward_supply?.rewards.find(
      (r) => r.reward_token_id === ENV_ID_TOKEN_NEL
    );

    if (!nel_reward) return;
    setCurrentReward(
      Number(
        Big(nel_reward?.asset_farm_reward.reward_per_day)
          .div(Big(nel_reward?.asset_farm_reward.boosted_shares))
          .mul(Big(nel_reward?.boosted_shares))
          .div(Big(10).pow(24))
          .toNumber()
          .toFixed(9)
          .slice(0, LAST_DECIMAL_REPLACE)
      )
    );
  }, [profile.userAssetFarms.supplied, tokenId]);

  useEffect(() => {
    _getPriceRealtime();
    _initGetPriceRealtime();
    return () => {
      clearInterval(refGetPriceRealtime.current);
    };
  }, [_initGetPriceRealtime, _getPriceRealtime]);

  useEffect(() => {
    _initReward();
  }, [_initReward]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isAllowToShow ? (
    <div className="wrap-info active">
      <Fragment>
        <BalanceTokenItem
          tokenIcon={currentTokenInPool?.icon ?? tokenIcon}
          tokenSymbol={tokenSymbol}
          tokenUsd={usd}
          tokenDecimals={tokenExtraDecimals + tokenContractDecimals}
          apy={supply_apr}
          balance={currentToken.balance}
          actions={[{ func: _handleToggleWithdraw, name: "Withdraw" }]}
          reward={currentReward}
        />
        {isShowWithdraw && (
          <PopupWithdraw
            togglePopup={_handleToggleWithdraw}
            currentToken={currentTokenInPool}
          />
        )}
      </Fragment>
    </div>
  ) : null;
}
