import {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
} from "react";
import { useQueryClient } from "react-query";
import {
  getTotalBalanceTokenPriceUSD,
  getTotalBalanceNftPriceUSD,
  getTotalInterestPriceUSD,
  formatCashToView,
} from "utils/common";
import { MAX_HEALTH_FACTOR_PERCENT, QUERY_KEY } from "utils/constant";
import { TTokenFormat } from "types/token";
import { AppContext } from "Contexts/AppContext";
import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import Big from "big.js";

export default function TotalBalance() {
  const queryClient = useQueryClient();
  let initInterval: any = useRef(null);

  const { profile, poolTokenList, poolNftList } = useContext(AppContext);

  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalBorrow, setTotalBorrow] = useState(0);
  const [healthFactor, setHealthFactor] = useState(0);
  const [netAPY, setNetAPY] = useState(0);

  const _initCurrHealthFactor = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;
    if (!getNewFormatToken || !profile?.userBalance) return;
    const { supplied, borrowed, nft_supplied } = profile?.userBalance || {
      supplied: 0,
      borrowed: 0,
    };

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      supplied,
      getNewFormatToken,
      poolTokenList
    );

    const nft_collateral_to_usd = getTotalBalanceNftPriceUSD(
      nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    const borrowed_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const total_collateral_to_usd = Number(
      Number(collateral_to_usd + nft_collateral_to_usd)
    );

    const healFactor =
      (total_collateral_to_usd / (!borrowed_to_usd ? 1 : borrowed_to_usd)) *
      100;

    setHealthFactor(healFactor);
  }, [poolNftList, poolTokenList, profile?.userBalance, queryClient]);

  const _calculate = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const getApy = queryClient.getQueryData(
      "GET_TOKEN_APY"
    ) as unknown as TTokenFormat;

    if (!getNewFormatToken || !profile?.userBalance) return;

    const { supplied, borrowed, nft_supplied } = profile?.userBalance || {
      supplied: 0,
      borrowed: 0,
    };

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      supplied,
      getNewFormatToken,
      poolTokenList,
      false
    );

    const nft_collateral_to_usd = getTotalBalanceNftPriceUSD(
      nft_supplied,
      getNewFormatToken,
      poolNftList,
      false
    );

    const borrowed_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      borrowed,
      getNewFormatToken,
      poolTokenList,
      false
    );

    const total_supply_interest = getTotalInterestPriceUSD(
      "collateral",
      supplied,
      getNewFormatToken,
      getApy
    );

    const total_borrow_interest = getTotalInterestPriceUSD(
      "borrowed",
      borrowed,
      getNewFormatToken,
      getApy
    );

    const total_collateral_to_usd =
      collateral_to_usd + Number(nft_collateral_to_usd.toFixed(4).slice(0, -2));
    if (!total_collateral_to_usd) return;

    const totalDeposit = Number(total_collateral_to_usd);
    const totalBorrowed = Number(borrowed_to_usd.toFixed(4).slice(0, -2));

    const net_apy = Big(total_supply_interest)
      .sub(Big(total_borrow_interest))
      .mul(100)
      .div(Big(total_collateral_to_usd))
      .toNumber();

    setNetAPY(net_apy);
    setTotalDeposit(totalDeposit);
    setTotalBorrow(totalBorrowed);
    _initCurrHealthFactor();
  }, [
    _initCurrHealthFactor,
    poolNftList,
    poolTokenList,
    profile?.userBalance,
    queryClient,
  ]);

  const _initCalculate = useCallback(() => {
    initInterval.current = setInterval(_calculate, 1000);
  }, [_calculate]);

  const classNameHF = useMemo(() => {
    if (!healthFactor) return "";
    if (healthFactor < 200) return "text-yellow";
    else return "text-green";
  }, [healthFactor]);

  useEffect(() => {
    _initCalculate();
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_initCalculate]);

  useEffect(() => {
    _calculate();
  }, [_calculate]);

  useEffect(() => {
    _calculate();
  }, [_calculate]);

  return (
    <div className="total-balance">
      <div className="wrap-total">
        <div className="total deposit">
          <div className="total-value">
            <p className="title">Total Deposits</p>
            <p className="value">
              ${formatCashToView(totalDeposit as unknown as number, 8)}
            </p>
          </div>
          <div className="total-value">
            <p className="title">Total Borrows</p>
            <p className="value">
              ${formatCashToView(totalBorrow as unknown as number, 8)}
            </p>
          </div>
        </div>
        <div className="total deposit">
          <div className="total-value">
            <p className="title">Net APY</p>
            <p className="value">
              {netAPY > 10000
                ? MAX_HEALTH_FACTOR_PERCENT
                : `${netAPY.toFixed(4).slice(0, -2)}%`}
            </p>
          </div>
          <div className="total-value">
            <p className={`title ${classNameHF}`}>
              Health Factor{" "}
              <Tooltip
                zIndex={109999}
                arrowPointAtCenter
                color="#0000006b"
                title="If your estimated Heath Factor is lower than 100%. You are not available to withdraw asset."
              >
                <InfoCircleFilled />
              </Tooltip>
            </p>
            <p className={`value health-factor ${classNameHF}`}>
              {healthFactor > 10000
                ? MAX_HEALTH_FACTOR_PERCENT
                : `${healthFactor.toFixed(4).slice(0, -2)}%`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
