import { Col, Row, Table } from "antd";
import styled from "styled-components";
import { COLORs } from "utils/colors";
import { Container } from "components/common/Container";
import { ReactComponent as ChartIco } from "images/piechart.svg";

import {
  BackGroundText,
  MiddleSpace,
  RelativeWrapper,
  TextPosition,
  Title,
} from "components/homepage/styles";
import bgCircleGrid from "images/roadmap/circleGrid.svg";

import ETH from "images/3DCoin/ETH.svg";
import NFT from "images/3DCoin/NFT.svg";
import bgPhoneOnHand from "images/roadmap/phoneWhand.svg";
import { useWindowDimensions } from "hooks/useWindowSize";
import { mobileBreakPoint } from "utils/constant";

function TokenomicUI() {
  const { width } = useWindowDimensions();

  return (
    <RelativeWrapper className="mobile-tokenomic-only">
      {width < mobileBreakPoint ? null : (
        <>
          <EthCoinBasePosition data-aos="fade-left">
            <ECoinBase className="moveation-none-rotate-floop" />
          </EthCoinBasePosition>
          <NftBasePosition data-aos="fade-right">
            <NftBase className="moveation-none-rotate" />
          </NftBasePosition>
        </>
      )}

      <OnhandPosition data-aos="fade-left">
        <Onhand className="clock-animated-transform-scale" />
      </OnhandPosition>
      <CustomSpace />
      <Container>
        <Title data-aos="fade-right">Tokenomics</Title>
        <TextPosition style={{ top: "28%", width: "100%", right: "-10%" }}>
          <div data-aos="fade-left" style={{ opacity: ".5" }}>
            <BackGroundText>NEL Token</BackGroundText>
          </div>
        </TextPosition>
      </Container>
      <RelativeWrapper>
        <Container>
          <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
            <Col xs={24} sm={24} md={16} lg={14} xl={14}>
              <div
                style={{ position: "relative", zIndex: 2 }}
                data-aos="fade-right"
              >
                <TokenomicTable />
              </div>
              <SumNel>
                <span className="box">
                  <span className=" nev-top label">Total Supply</span>
                  <span className="nev-top value">100.000.000 NEL</span>
                </span>
              </SumNel>
              <HighLightBG />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={10}
              lg={10}
              xl={10}
              style={{ position: "relative", top: "-50px" }}
              className="mobile-pie-chart-only"
            >
              <ChartUI data-aos="zoom-out-up">
                <ChartIco
                  height="450px"
                  style={{ position: "relative", zIndex: 2 }}
                />
                <div style={{ position: "absolute", bottom: 0, right: "-20%" }}>
                  <Circlegrid />
                </div>
              </ChartUI>
            </Col>
          </Row>
        </Container>
      </RelativeWrapper>
    </RelativeWrapper>
  );
}

export default TokenomicUI;

const Circlegrid = styled.div`
  background-image: url(${bgCircleGrid});
  background-repeat: no-repeat;
  background-size: contain;
  width: 250px;
  height: 250px;
`;

const HighLightBG = styled.div`
  position: absolute;
  z-index: 2;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #54a943 0%,
    rgba(106, 169, 67, 0) 100%
  );
  opacity: 0.15;
  width: 500px;
  height: 500px;
  left: -30%;
  top: -2%;
`;

const CustomSpace = styled(MiddleSpace)`
  height: 120px;
`;

const ChartUI = styled.div``;

const SumNel = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .box {
    background: rgba(3, 18, 32, 1);
    padding: 10px 30px;
    border: 2px solid ${COLORs.GREEN_LINE};
    border-radius: 6px;
    display: flex;
    align-items: center;

    .nev-top {
      position: relative;
      /* top: -5px; */
    }
    .label {
      margin-right: 15px;
      white-space: nowrap;
      font-size: 18px;
    }
    .value {
      color: ${COLORs.GREEN_LIGHT};
      font-weight: 700;
      font-size: 24px;
      white-space: nowrap;
    }
  }
`;

interface TableProperties {
  key: number;
  category: string;
  amount: string;
  vesting: string;
}

const TokenomicTable = () => {
  return (
    <TableWarpper
      bordered
      pagination={false}
      columns={columns}
      dataSource={data}
      rowClassName={(_, index) =>
        index % 2 === 0 ? `table-row-dark` : `table-row-light `
      }
      scroll={{ x: 350 }}
    />
  );
};
const TableWarpper = styled(Table)`
  border-radius: 6px;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(84, 169, 67, 0) 0%,
      rgba(84, 169, 67, 0.2) 100%
    ),
    rgba(84, 169, 67, 0.1);

  backdrop-filter: blur(14px);
  .ant-table {
    background-color: transparent;
    color: white;
  }
  .table-row-dark {
    background: rgba(0, 18, 33, 0.4);
  }
  table {
    border-top: solid 1px rgba(106, 169, 67, 0.3) !important;
  }
  .ant-table-thead > tr > th {
    background-color: transparent;
    color: white;
    font-weight: 700;
    font-size: 16px;
    border-right: solid 1px rgba(106, 169, 67, 0.3) !important;
    border-bottom: solid 1px rgba(106, 169, 67, 0.3) !important;
    text-align: center;
  }
  td.ant-table-cell {
    padding: 12px 16px !important;
    font-weight: 700;
    .no-wrap {
      font-size: 12px;
    }
    span {
      font-weight: 700;
    }
  }
  thead.ant-table-thead {
    background: linear-gradient(180deg, rgba(84, 169, 67, 0) 0%, #54a943 100%),
      linear-gradient(
        180deg,
        rgba(84, 169, 67, 0.4) 0%,
        rgba(84, 169, 67, 0) 100%
      );
    backdrop-filter: blur(14px);
    &:hover {
      background: linear-gradient(180deg, rgba(84, 169, 67, 0) 0%, #54a943 100%),
        linear-gradient(
          180deg,
          rgba(84, 169, 67, 0.4) 0%,
          rgba(84, 169, 67, 0) 100%
        ) !important;
    }
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: solid 1px rgba(106, 169, 67, 0.3) !important;
  }
  table:hover,
  tr:hover,
  thead:hover {
    background: transparent !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: solid 1px rgba(106, 169, 67, 0.3) !important;
    border-right: solid 1px rgba(106, 169, 67, 0.3) !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
`;

const columns = [
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Vesting",
    dataIndex: "vesting",
    key: "vesting",
    render: (des: any) => <span className="no-wrap">{des}</span>,
  },
];

const data: TableProperties[] = [
  {
    key: 1,
    category: "Liquidity Mining",
    amount: "45.000.000",
    vesting: "Rewards for users over 09 years",
  },
  {
    key: 2,
    category: "Teams & Advisors ",
    amount: "16.000.000",
    vesting: "Linear vesting over 04 years",
  },
  {
    key: 3,
    category: "Token Sale",
    amount: "18.000.000",
    vesting: "Pre-Seed, Seed Sales, Private Sales",
  },
  {
    key: 4,
    category: "Reserve & Treasury",
    amount: "21.000.000",
    vesting: "To be announced",
  },
];

const EthCoinBasePosition = styled.div`
  position: absolute;
  bottom: -50%;
  z-index: 2;
  right: 3%;
`;
const NftBasePosition = styled.div`
  position: absolute;
  bottom: -35%;
  z-index: 2;
  left: 3%;
`;
const OnhandPosition = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 2;
`;

const ECoinBase = styled.div`
  background-image: url(${ETH});
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 120px;
`;
const NftBase = styled.div`
  background-image: url(${NFT});
  background-repeat: no-repeat;
  background-size: contain;
  width: 175px;
  height: 175px;
`;

const Onhand = styled.div`
  /* background-image: url(${bgCircleGrid});
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 200px; */
  position: relative;
  z-index: 2;
  &::after {
    content: "";
    position: absolute;
    background-image: url(${bgPhoneOnHand});
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 200px;
    right: -10%;
  }
`;
