import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";

import {
  checkNegativeNumber,
  getTotalBalanceTokenPriceUSD,
} from "utils/common";
import { handleBorrow } from "utils/connect/contract";
import useCurrentToken from "hooks/useCurrentToken";
import ActionPopupView from "components/popup/component/ViewActionPopup";
import { EQueryKey, TTokenFormat } from "types/token";
import { AppContext } from "Contexts/AppContext";

const { GET_FORMAT_TOKEN } = EQueryKey;

type Props = {
  togglePopup: () => void;
  currentToken: any;
};
const Borrow = ({ togglePopup, currentToken }: Props) => {
  let initInterval: any = useRef(null);

  const { tokenName, tokenIcon, tokenSymbol, tokenConfig, borrow_apr } =
    useCurrentToken(currentToken);

  const { contract, userBalance, poolListToken }: any = useContext(AppContext);

  const [amountToken, setAmountToken] = useState(0);
  const [available, setAvailable] = useState<any>(0);
  const [tokenUsdPrice, setTokenUsdPrice] = useState(0);
  const [totalUsd, setTotalUsd] = useState<any>(0);
  const [currentMaturity, setCurrentMaturity] = useState(30);
  const [error, setError] = useState("");

  // React query
  const queryClient = useQueryClient();

  // Other
  const _handleBorrow = useCallback(() => {
    if (available === 0) {
      return setError(`You need to deposit before borrow`);
    } else if (amountToken === 0 || amountToken === null) {
      return setError(`You have to Enter amount of Tokens`);
    } else if (amountToken > available) {
      return setError(`You out of Limits Available`);
    } else if (amountToken < 0) {
      return setError(`You can not borrow with Negative number`);
    } else if (amountToken < 0) {
      return setError(`You can not use Negative number`);
    }
    return handleBorrow(currentToken, amountToken, contract);
  }, [amountToken, available, contract, currentToken]);

  const _onChange = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const _onChangeSlider = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const _calculate = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      userBalance?.collateral,
      getNewFormatToken,
      poolListToken
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      userBalance?.borrowed,
      getNewFormatToken,
      poolListToken
    );

    const { usd } = tokenConfig;

    const cal2 = checkNegativeNumber(
      Number(borrow_to_usd),
      Number(collateral_to_usd)
    );

    setTokenUsdPrice(usd);
    setTotalUsd(cal2);
  }, [
    poolListToken,
    queryClient,
    tokenConfig,
    userBalance?.borrowed,
    userBalance?.collateral,
  ]);

  const _initCalculate = useCallback(() => {
    initInterval.current = setInterval(_calculate, 300);
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_calculate]);

  const _initAvailable = useCallback(() => {
    if (totalUsd && tokenUsdPrice) {
      const available = totalUsd / tokenUsdPrice;
      setAvailable(available.toFixed(3));
    }
  }, [tokenUsdPrice, totalUsd]);

  useEffect(() => {
    _initAvailable();
  }, [_initAvailable]);

  useEffect(() => {
    _initCalculate();
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_initCalculate]);

  return (
    <ActionPopupView
      isYellow={true}
      isRequireMaturity={true}
      currentMaturity={currentMaturity}
      cbSetState={
        setCurrentMaturity as Dispatch<SetStateAction<number>> as (
          numb: any
        ) => void
      }
      textTitle="Borrow (Stable APY)"
      togglePopup={togglePopup}
      onChange={_onChange}
      onChangeSlider={_onChangeSlider}
      confirmPopUp={_handleBorrow}
      error={error}
      valAPY={borrow_apr}
      currentToken={{
        available,
        tokenName,
        tokenSymbol,
        tokenUsdPrice,
        tokenIcon,
      }}
    />
  );
};

export default Borrow;

// const maturityDates = [30, 60, 90];
