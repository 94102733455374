import { IAuthContext } from "utils/interfaces";

const Auth: IAuthContext = {
  isLoggedIn: false,
  account: {
    accountId: "",
    accountName: "",
  },
  setAuthParams: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

export default Auth;
